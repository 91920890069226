import React, { useEffect, useState } from "react";
import SuperAdminHome from "../Admin/SuperAdminHome";
import ScrollingMessage from "./ScrollingMessage";

function Home() {
  
  const [userRole , setUserRole] = useState("")

  useEffect(()=>{
    setUserRole(localStorage.getItem("user_role"));
    
  },[])
  

  
  return (

    (userRole === '1' 
    ? 
     <SuperAdminHome/>
    :
        <>
            <div className="w-full ml-11">
                <ScrollingMessage />
            </div>
        <div className="h-screen flex justify-center place-items-center flex-col text-center">
            <h1 className=" text-[#081159] text-3xl font-bold pt-2 ">
              Welcome
            </h1>
            <p className=" w-[400px] text-gray-500 mt-3">
              "Step into the World of Invoicing Excellence! Your Ultimate Solution for
              Effortless Invoicing.
            </p>
      </div>
      </>
     )
  )
}

export default Home;
