const menuList = {
  HOME: "Home",
  SALES:'Sales',
  PURCHASES : 'Purchases',
  EXPENSES : 'Expenses',
  DEPOSITS : 'Deposits',
  DOCUMENTS : 'Documents',
  BILLING: "Billing",
  PAYMENTS:'Payments',
  SETUP:'Setup',
  // CUSTOMERS: "Customers",
  CUSTOMERS: "Business",
  PRODUCTS: "Products",
  ITEMS:'Items',
  INVENTORY: "Inventory",
  REPORTS:'Reports',
  ACCOUNTING:'Accounting', 
  KPI:'KPI',
  INVOICE_FORM: "Invoiceform",
  CUSTOMER_FORM: "Customerform",
  VIEW_CUSTOMER:'Viewcustomer',
  ANALYTICS:'Analytics',
  NOTIFICATIONS : 'Notifications'



}

export { menuList };


