const routes = {
  HOME: "/",
  INVOICE: "/invoice",  // also called Sales in sidebar
  BILLING: "/billing",
  PAYMENTS:'/payments',  // payments in side bar
  SETUP:'/setup',
  PURCHASES : '/purchases',  // purchases in side bar
  EXPENSES : '/expenses' ,   // expenses in side bar
  DEPOSITS : '/deposits',   // deposits in side bar
  DOCUMENTS : '/documents',   // documents in side bar
  PURCHASES_DETAILS : '/purchases/:id',
  CUSTOMERS: "/customers",
  PRODUCTS: "/products",
  ITEMS:'/items',
  ITEMS_DASHBOARD : '/itemsdashboard', 
  VENDORS : '/vendors',    // vendors in setting icon
  INVENTORY: "/inventory",
  REPORTS:'/reports',
  ACCOUNTING:'/accounting',
  KPI:'/kpi',
  INVOICE_FORM: "/invoiceform",
  CUSTOMER_FORM: "/customerform",
  VIEW_CUSTOMER:'/viewcustomer',
  ANALYTICS:"/analytics",
  BUSINESS:"/business", 
  BUSINESS_DASHBOARD : "/businessdashboard",
  VIEW_BUSINESS : "/viewbusiness",
  // LOGIN_PAGE : "/loginpage",
  ADMIN_LOGIN : "/adminlogin",
  USER_LOGIN_PAGE : "/userloginpage",
  FORGOT_PASSWORD : "/forgotpassword",
  SIGNUP_PAGE : "/signup",
  OTP_PAGE : "/otp",
  VIEW_BUSINESS_USERS : "/ViewBusinessUsers",
  CREATE_OWNER : "/createowner",
  CREATE_OWNER_MAIN : "/createownermain",
  CREATE_BUSINESS : "/createbusiness",
  USERS_DASHBOARD : "/usersdashboard",
  ADMIN_BUSINESS_DASHBOARD : "/adminbusinessdashboard",
  ITEM_PRICE : "/itemprice",
  INVOICE_FORM_TWO : "/invoiceformtwo",
  VIEW_INVOICE_FORM : "/viewinvoiceform",
  VIEW_INVOICE_FORM_TWO : "/viewinvoiceformtwo",
  CREATE_VENDOR : '/createvendor',
  VIEW_VENDOR : '/viewvendor',
  NOTIFICATIONS : '/notifications',
  EDIT_NOTIFICATIONS: '/editnotifications/:id',
  EDIT_ADMIN_BUSINESS : "/editadminbusiness/:id",
  EDIT_ADMIN_OWNER : "/editadminowner/:id"
};

export { routes };
