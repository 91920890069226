import React from 'react'
import moment from 'moment'; 
import { useState , useEffect } from 'react';
import axios from "axios";
import { useRef } from 'react';
import {addInvoice,} from "../../actions/invoiceActions";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdCalendarMonth } from "react-icons/md";
import config  from "../../config/config"
import ReactDOM from "react-dom"
import { showToastForUpdateInvoice } from '../toasts/toastForInvoice';

const ViewInvoiceForm = () => {

    const business_id = localStorage.getItem("business_id")
    const user_id = localStorage.getItem("user_id")
    const user_role = localStorage.getItem("user_role")

    const {invoicenumber} = useParams()
    const [invoiceData, setInvoiceData] = useState([]);
  
    const [isInputFocused, setIsInputFocused] = useState(true);
    const today = new Date().toISOString().split("T")[0];
    const [issuedOn, setIssuedOn] = useState(today);
    const [dueOn, setDueOn] = useState(today);
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProductItems, setSelectedProductItems] = useState([]);
    const [availableDescriptions, setAvailableDescriptions] = useState([]);
    const [availableDescriptionsMap, setAvailableDescriptionsMap] = useState({});
  
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState("");
    const [feesOrDiscounts, setFeesOrDiscounts] = useState("");
    const [total, setTotal] = useState(0);
  
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [comments, setComments] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState(""); 
  
    const [dueDate, setDueDate] = useState(new Date());
    const [createdDate , setCreatedOnDate ] = useState(new Date()); 
    const businessId = localStorage.getItem("business_id")
    const [selectedProductId, setSelectedProductId] = useState("");
  
    
    const [showDropdown, setShowDropdown] = useState(false);
    const [customerOptions , setCustomerOptions] = useState([])
    const [taxTotal ,setTaxTotal] = useState(); 

    const [itemData, setItemData] = useState([]);
    const [newTotal, setNewTotal] = useState(0);
   
    const navigate = useNavigate()
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

    const showToast = (message, type, businessName) => {
      const toastColorClass = type === "success" ? "bg-blue-50 text-blue-500" : "bg-red-50 text-red-500";
      const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#2b6ade]" size={24} /> : <FaTimesCircle className="mr-2 text-red-500" size={24} />;
    
      const iconContainer = document.createElement('div');
      ReactDOM.render(iconComponent, iconContainer);
      
      const toastContainer = document.createElement('div');
      toastContainer.className = `toast-container flex justify-between items-center ${toastColorClass}`;
      
      toastContainer.innerHTML = `
        <div className="rounded-lg" style="white-space: nowrap; width: 600px;"> 
          ${message}
        </div>
        <div className="rounded-lg">${iconContainer.innerHTML}</div>
      `;
      
      toastContainer.style.position = 'fixed';
      toastContainer.style.top = '495px'; 
      toastContainer.style.left = '800px';
      toastContainer.style.transform = 'translate(-50%, -50%)'; // Center the container horizontally and vertically
      toastContainer.style.zIndex = '9999';
      toastContainer.style.padding = '10px';
      toastContainer.style.borderLeft = type === "success" ? "5px solid #2b6ade" : "5px solid red";
      toastContainer.style.background = type === "success" ? "#f3faf7" : "#fdf2f2";
      toastContainer.style.minHeight = "10px";
      toastContainer.style.display = "flex";
      toastContainer.style.justifyContent = "space-between";
      toastContainer.style.width = "auto"; // Adjust width as needed
    
      document.body.appendChild(toastContainer);
    
      const initialTop = parseFloat(getComputedStyle(toastContainer).top);
    
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const distance = initialTop - scrollTop;
        toastContainer.style.top = `${distance}px`; // Adjust position based on scroll
      };
    
      window.addEventListener('scroll', handleScroll);
    
      setTimeout(() => {
        document.body.removeChild(toastContainer);
        window.removeEventListener('scroll', handleScroll);
        window.scrollTo(0, 0); // Move screen back to top after dismissing toast
      }, 3500);
    };
    
    
    const [items, setItems] = useState([
      { 
        productId : "",
        itemId : "",
        item: "",
        description : "",
        quantity: "",
        unitPrice: "",
        total: 0,
      },
    ]);


    console.log("items", items);

    // console.log("tax value results :" , taxValuesResults);

    const [formField, setFormField] = useState({
      invoice_number: "",
      bill_from: "",
      bill_to: "",
      created_on: "",
      due_on: "",
    });
  
    const handleChange = (event) => {
      if (event instanceof Date) {
        setCreatedOnDate(event);
      }
      const { name, value } = event.target;

      if (name === "bill_to") {
        setFormField({
          ...formField,
          [name]: value,
        });
      } else {
        setFormField({
          ...formField,
          [name]: value,
        });
      }
    };

    const handleDueDateChange = (date) => {
      setDueDate(date);
  
      setFormField({
        ...formField,
        due_on: date,
      });
    };
  
    const datePickerRef = useRef(null);
    const handleOuterDivClick = () => {
      // Simulate a click on the input element of the date picker
      if (datePickerRef.current) {
        datePickerRef.current.querySelector('input').click();
      }
    };

    const handleTaxChange = (value) => {
      const numericValue = value === "" ? "" : value.replace(/[^0-9.]/g, '').slice(0, 15);
      if (/^\d*\.?\d*$/.test(numericValue)) {
          const newTax = numericValue === "" ? 0 : parseFloat(numericValue);
          setTax(newTax);
          const numericSubTotal = parseFloat(subTotal);
          const numericFeesOrDiscounts = parseFloat(feesOrDiscounts);
          if (!isNaN(numericSubTotal) && !isNaN(numericFeesOrDiscounts)) {
              const newTotal = numericSubTotal + numericSubTotal * (newTax / 100) + numericFeesOrDiscounts;
              setTotal(newTotal);
          } else {
              console.error("Subtotal or fees/discounts are not valid numbers");
          }
      }
  };
  
    

    const handleFeesChange = (value) => {
      const numericValue = value === "" ? "" : value.replace(/[^0-9.]/g, '').slice(0, 15);
      if (/^\d*\.?\d*$/.test(numericValue)) {
        const newFees = numericValue === "" ? 0 : parseFloat(numericValue);
        setFeesOrDiscounts(newFees); 
        const newTotal = subTotal + subTotal * (tax / 100) - newFees;
        setTotal(newTotal);   
      } 
    };
    
  
  
    const handleSubmit = (e) => {
      e.preventDefault()
      console.log("items submitted " , items);
      const formattedCreatedDate = formField.created_on instanceof Date && !isNaN(formField.created_on)
      ? formField.created_on.toLocaleDateString('en-US') // Convert to local date string
      : new Date().toLocaleDateString('en-US');
    
    const formattedDueDate = formField.due_on instanceof Date && !isNaN(formField.due_on)
      ? formField.due_on.toLocaleDateString('en-US') // Convert to local date string
      : new Date().toLocaleDateString('en-US');
    
    // const formattedDueDate = formField.due_on instanceof Date && !isNaN(formField.due_on)
    // ? new Date(formField.due_on.getTime() + (24 * 60 * 60 * 1000)).toISOString() // Subtract one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    // : new Date().toISOString();

    console.log("format edit date", formattedDueDate);

    
      const productId = [];
      const itemIds = [];
      const description = [];
      const quantities = [];
      const unitPrices = [];
      const unitTotals = [];
      items.forEach((item, index) => {
        if (item.itemId || item.item || item.quantity || item.unitPrice) {
          productId.push(item.productId);
          itemIds.push(item.itemId);
          description.push(item.description);
          quantities.push(item.quantity);
          unitPrices.push(item.unitPrice);
          unitTotals.push(item.total);
        }
      });
      const payload = {
        invoiceNumber: formField.invoice_number,
        businessId: localStorage.getItem("business_id"),
        managerId : localStorage.getItem("user_id"),
        customerId: formField.bill_to,
        invoiceQuantity: totalQuantity,
        dueDate : formattedDueDate,
        subTotalAmount: subTotal,
        salesTaxAmount: tax,
        discountsAmount: feesOrDiscounts,
        finalTotalAmount: total,
        comment: comments,
        itemIds: itemIds,
        itemDescriptions : description,
        quantities: quantities,
        unitPrices: unitPrices,
        unitTotals: unitTotals,
        productId : productId,
        termsCondition : termsAndConditions,
      };

    
      async function putData() {
      axios
      .put(`${baseURL}${btoa("invoice/update")}`, payload)
      .then((response) => {
        console.log("update invoice " , response); 
        console.log("payload" , payload);
        showToastForUpdateInvoice(`Invoice Updated Successfully`, "success");
        navigate("/invoice");
      })
      .catch((error) => {
        console.log("payload" , payload);
        console.log("update invoice error " , error)
        showToast(`Invoice Update failed`, "error");
      });
      }
      putData();
    };
  
    useEffect(() => {
      async function fetchProductOptions() {
        try {
          const response = await axios.get(
            `${baseURL}${btoa("retailproduct/view")}/${btoa(businessId)}`
          );
          console.log("response");
          // Assuming the response.data is an array of products
          setProductOptions(response.data.products_data);
          console.log("product options" , productOptions);
        } catch (error) {
          console.error("Error fetching product options:", error);
        }
      }
      fetchProductOptions();
    }, []);

    useEffect(() => {
      async function fetchProductOptions() {
        try {
          const response = await axios.get(
            `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
          );
          setCustomerOptions(response.data.customers_data);
          console.log("customer data" , response.data.customers_data);
        } catch (error) {
          console.error("Error fetching product options:", error);
        }
      }
      fetchProductOptions();
    
    }, []);

    useEffect(() => {
      window.scrollTo(0, 0);
      axios
        .get(`${baseURL}${btoa("items/view")}/${btoa(businessId)}`)
        .then((response) => {      
          setItemData(response.data.items_data);
          // console.log("item put item " , response.data.items_data);
        })
        .catch((error) => {
          console.error("Error fetching customer data:", error);
        });
    }, []);
    console.log(itemData)
  
    
    
  
    
  
    const shouldRenderButtons = items.some(
      item => item.item !== "" && item.unitPrice !== "" && item.unitPrice !== 0 && item.quantity !== "" &&
       item.quantity !== 0 
    );
    console.log("button ", shouldRenderButtons);
  
    console.log("al the items" , items);
  

      const totalQuantity = items.reduce((acc, item) => acc + (parseFloat(item.quantity) || 0), 0);
      const totalPrice = items.reduce((acc, item) => acc + parseFloat(item.unitPrice || 0), 0);
      const subTotal = items.reduce((acc, item) => acc + parseFloat(item.total || 0), 0);

    const grandTotal = parseFloat(taxTotal) + parseFloat(subTotal);

   
    
    useEffect(()=>{
      const fetchDataByInvoice = async () => {
        try {
          const apiUrl = `${baseURL}${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoicenumber)}/${btoa(businessId)}`;
          const response = await axios.get(apiUrl);
          console.log("invoice on on invoice number: " , response);
        
          setInvoiceData(response.data.customerData[0]);
          const createdOnString = response.data.customerData[0].added_on;
          const formattedCreatedOn = moment(createdOnString, 'YYYY/MM/DD').format('MM/DD/YYYY');
          console.log("formatted created on" , formattedCreatedOn);
          const dueOnString = response.data.customerData[0].due_date;
          const formattedDueOn = moment(dueOnString, 'YYYY/MM/DD').format('MM/DD/YYYY');
          console.log("formatted due on" , formattedDueOn);
          setComments(response.data.customerData[0].comment)
          setTermsAndConditions(response.data.customerData[0].terms_conditions)
          setFormField({
              invoice_number: response.data.customerData[0].invoice_number,
              bill_from: response.data.customerData[0].bill_from_name,
              bill_to: response.data.customerData[0].customer_id,
              created_on:  new Date(formattedCreatedOn), 
              due_on: new Date(formattedDueOn),
          })
          setTax( response.data.customerData[0].sales_tax_amount)
          setFeesOrDiscounts(response.data.customerData[0].discounts_amount)
          setTotal(response.data.customerData[0].final_total_amount)
            const formattedItems = response.data.invoiceItemsData.map((item) => ({
              productId : item.product_id,
              itemId : item.Item_id,
              item: item.Item_name,
              description : item.Item_des,
              quantity: item.item_quantity,
              unitPrice: item.item_price,
              total: item.unit_total,
            }));
            setItems(formattedItems);
        } catch (error) {
          console.error("Error fetching invoice by invoice number", error);
        }
      };
      fetchDataByInvoice()
    },[])


    // useEffect(() => {
    //   const isLastRowNew = items?.length > 0 && items[items?.length - 1].No === items?.length;
    //   if (itemData?.length > items?.length && !isLastRowNew) {
    //     setItems(prevItems => [
    //       ...prevItems,
    //       {
    //         productId : "",
    //         itemId : "",
    //         item: "",
    //         description : "",
    //         quantity: "",
    //         unitPrice: "",
    //         total: 0, 
    //       }
    //     ]);
    //   }
    // }, [items, itemData]);

    const handleProductChange = (index, field, value) => {
      setSelectedProductId(value);
      console.log("Selected product ID:", value);
  
      if (!value) {
        // If the user deselects the product, clear the selectedProductItems and update items
        const updatedItems = [...items];
        updatedItems[index] = {
          ...updatedItems[index],
          productId: "", // Clear the product ID
          [field]: ""   // Clear the product value
        };
        console.log("Updated items after deselection:", updatedItems);
        setItems(updatedItems);
      } else {
        // If a product is selected, fetch and update selectedProductItems
        axios
          .get(`${baseURL}${btoa("items/getitemsbyproduct")}/${btoa(businessId)}/${btoa(value)}`)
          .then((response) => {
            setSelectedProductItems(response.data.itemsByProduct);
            console.log("Items by product:", response.data.itemsByProduct);
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              productId: value, // Set the product ID
              // Clear the item ID and description when product changes
              itemId: "",
              description: "",
              [field]: value   // Set the product value
            };
            console.log("Updated items after selection:", updatedItems);
            setItems(updatedItems);
          })
          .catch((error) => {
            console.error("Error fetching items for the selected product:", error);
          });
      }
    };

    useEffect(() => {
      const fetchCustomerTermsAndConditions = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/fetchtermsconditions")}/${btoa(formField.bill_to)}/${btoa(businessId)}`);
          // console.log("tandc",response , );
          if(response.data.termsconditions_data?.length === 0){
            setTermsAndConditions("")
          }
          setTermsAndConditions(response.data.termsconditions_data[0].terms_conditions);
        } catch (error) {
          console.error('Error fetching customer terms and conditions', error);
        }
      };
      fetchCustomerTermsAndConditions();
    }, [formField.bill_to]);
    
    
    
    
  
    const handleItemChange = (index, field, value) => {
      const updatedItems = [...items];
      updatedItems[index][field] = value;

      const matchingItem = selectedProductItems.find(item => item.Item_name === value);
      if (matchingItem) {
        updatedItems[index].description = matchingItem.Item_des;
        updatedItems[index].unitPrice = matchingItem.item_unit_price;
        updatedItems[index].itemId = matchingItem.Item_id;
      }
      updatedItems[index].total = updatedItems[index].quantity * updatedItems[index].unitPrice;
      if (value === "Enter Description" && field === "description") {
        updatedItems[index].editableDescription = true;
        setShowDropdown(true); 
      } else {
        updatedItems[index].editableDescription = false;
        setShowDropdown(false);
      }
      if (field === "quantity" && index === updatedItems?.length - 1 && value !== "") {
        updatedItems.push({
          productId: "",
          itemId: "",
          item: "",
          description: "",
          quantity: "",
          unitPrice: "",
          total: 0,
          editableDescription: false,
        });
        setShowDropdown(false); // Close dropdown for the new row
      }
  
      // Calculate subtotal and total
      const newSubtotal = updatedItems.reduce((acc, item) => acc + parseFloat(item.total || 0), 0); // Ensure item.total is parsed as a number
      const calculatedTax = newSubtotal * (tax / 100); // Ensure tax is a number
      const calculatedFeesOrDiscounts = parseFloat(feesOrDiscounts) || 0; // Ensure feesOrDiscounts is parsed as a number
      const newTotal = newSubtotal + calculatedTax - calculatedFeesOrDiscounts;
  
      // console.log("New Subtotal:", newSubtotal);
      // console.log("Calculated Tax:", calculatedTax);
      // console.log("Calculated Fees/Discounts:", calculatedFeesOrDiscounts);
      // console.log("New Total:", newTotal)
      // Update state
      setItems(updatedItems);
      setSubtotal(newSubtotal);
      setTotal(newTotal);
    };
    

    const handleDescriptionChange = (index, value) => {
      const updatedItems = [...items];
      if (value === "custom") {
        updatedItems[index] = {
          ...updatedItems[index],
          editableDescription: true
        };
      } else {
        updatedItems[index] = {
          ...updatedItems[index],
          description: value
        };
      }
      setItems(updatedItems);
    };  
  
    const formatNumber = (number) => {
      if (isNaN(number)) {
        return 0;
      }
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
      return formattedNumber;
    };
  
   
  
    const isDisabled = items.every(
      item =>
        (item.item === "" && item.unitPrice === "" && (item.quantity === "" || isNaN(item.quantity)) && item.product === "")
        ||
        (item.item !== "" && item.unitPrice !== "" && (item.quantity !== "" && !isNaN(item.quantity)) && item.product !== "")
    );
    console.log("disable " , isDisabled);

    const showChildRecord = items.every(
      item =>
        (item.item === ""  && (item.quantity === "" || isNaN(item.quantity)) && item.product === "")
        ||
        (item.item !== ""  && (item.quantity !== "" && !isNaN(item.quantity)) && item.product !== "")
    );
    console.log("chil record" , showChildRecord);

   

  return (
    <div className="pt-8 pb-5">
    <div className="max-w-7xl mx-8 bg-white rounded-box p-10 rounded-xl">
        <div className="flex items-center justify-between w-full">
            <h2 class="mb-10 text-2xl font-bold text-gray-900 ">
              Edit Invoice
            </h2>
        <div className="flex items-center">
          <label
            for="invoice_number"
            className="  text-base font-bold text-gray-900 w-28 mr-2"
          >
            Invoice #
          </label>
          <input
            readOnly
            id="invoice_number"
            name="invoice_number"
            type="text"
            value={formField.invoice_number}
            onChange={handleChange}
            placeholder="Enter Invoice Number"
            className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
          />
        </div>
        </div>

      <form action="#" 
      // onSubmit={handleSubmit}
      >
         <div className="grid gap-4 mb-4 sm:grid-cols-2">
          <div>
            <label
              for="bill_from"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Bill From
            </label>
            <input
              id="bill_from"
              type="text"
              name="bill_from"
              required
              value={formField.bill_from}
              onChange={handleChange}
              placeholder="Enter From details"
              className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              readOnly
            />
          </div>
          <div className="">
            <label
              htmlFor="created_on"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Created On
            </label>

            <div className="h-11 text-gray-700 font-normal border-gray-300 bg-gray-200  cursor-pointer border rounded-lg flex items-center justify-between">
                <DatePicker
                    id="created_on"
                    name="created_on"
                    selected={formField.created_on}
                    dateFormat="MM/dd/yyyy"        
                    placeholderText="From"
                    readOnly
                    className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                />
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
            </div>
          

          </div>

          <div>
            <label
              for="bill_to"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Bill To
            </label>
            <select
              id="bill_to"
              name="bill_to"
              required
              value={formField.bill_to}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Customer</option>
              {customerOptions.map((customer) => (
                <option
                  key={customer.customer_id}
                  value={customer.customer_id}
                >
                  {customer.business_name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="due_on"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Due Date
            </label>

            <div
                className="h-11 font-normal border-gray-300 cursor-pointer border rounded-lg flex items-center justify-between"
                onClick={handleOuterDivClick}
              >
                <div ref={datePickerRef}>
                  <DatePicker
                    name="due_on"
                    id="due_on"
                    dateFormat="MM/dd/yyyy"
                    minDate={new Date()}
                    selected={formField.due_on}
                    onChange={handleDueDateChange}
                    placeholderText="To"
                    className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                  />
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
            </div>



          </div>
        </div>

            {/*From here invoice items*/}

        <div className="mb-4">
          <h3 className="text-lg font-bold underline mb-2 mt-10 text-gray-900 ">
            Invoice Items
          </h3>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th style={{ width: "50px" }} className="pt-2 pb-2 pl-0 font-semibold text-base">#</th>
                  <th style={{ width: "150px" , marginLeft : "" }}  className="p-2 font-semibold pr-5 text-base">Product</th>
                  <th style={{ width: "150px" }}  className="p-2 font-semibold pr-2 text-base">Item</th>
                  <th style={{ width: "180px" }}  className="p-2 font-semibold  text-base">Item Description</th>
                  <th style={{ width: "60px" ,marginLeft: "90px" }} className="p-2 font-semibold text-base">Quantity</th>
                  <th style={{ width:"px" , marginLeft: "38px" , position:"absolute"  }} className="p-2 font-semibold text-base">Unit Price</th>
                  <th style={{ width : "70px",  marginRight: "-10px"}} className="p-2 font-semibold text-base">Total</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td className="p-2 pl-4">{index + 1}</td>

                    <td className="p-2">
                      <select
                        value={item.productId}
                        onChange={(e) => {
                          handleProductChange(index, "product", e.target.value );
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                        style={{
                          width: "130px"
                        }}
                      >
                        <option value="">Select Product</option>
                        {productOptions?.map((product) => {
                          return (
                              <option key={product.product_id} value={product.product_id}>
                              {product.product_name}
                            </option>
                          );
                        })}
                      </select>
                    </td>

                    <td className="p-2">
                      <select
                        onChange={(e) => {
                          handleItemChange(index, "item", e.target.value);
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                        style={{ width: "130px", marginLeft: "12px" }}
                        value={item.item} // Pre-select the value
                      >
                        <option value="">Select Item</option>
                        {itemData?.map((selectedItem) => {
                          // Check if the current item is already selected in another row
                          const isItemSelectedInOtherRow = items.some(
                            (otherItem, otherIndex) => otherIndex !== index && otherItem.item === selectedItem.Item_name
                          );

                          // Render the option only if it's not selected in another row
                          if (!isItemSelectedInOtherRow) {
                            return (
                              <option
                                key={selectedItem.Item_id} // Assuming Item_id is the unique identifier for items
                                value={selectedItem.Item_name} // Use the unique identifier here
                              >
                                {selectedItem.Item_name}
                              </option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </select>
                    </td>

                    <td className="p-2">
                      {item.editableDescription ? (
                        <input
                          type="text"
                          placeholder="Enter Description"
                          value={item.description}
                          onChange={(e) => handleDescriptionChange(index, e.target.value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                          style={{ width: "180px", marginLeft: "12px" }}
                        />
                      ) : (
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                          style={{ width: "180px", marginLeft: "12px" }}
                          value={item.description} 
                          onChange={(e) => handleDescriptionChange(index, e.target.value)}
                        >
                          <option value="custom">Enter Description</option>
                          <option value={item.description}>{item.description}</option>
                        
                        </select>
                      )}
                    </td>



                    <td className="p-2">
                      <input
                        type="number"
                        value={item.quantity}
                        placeholder="Enter Quantity"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const parsedValue = inputValue;

                          if (!isNaN(parsedValue)) {
                            handleItemChange(index, "quantity", parsedValue);
                          } else {
                            handleItemChange(index, "quantity", "");
                          }
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                        style={{
                          width: "130px",
                          marginLeft: "10px",
                        }}
                      />
                    </td>

                    <td className="p-2">
                      <input
                        type="number"
                        id="number"
                        name="number"
                        readOnly
                        value={item.unitPrice}
                        placeholder="Enter unit price"
                        onChange={(e) =>
                          handleItemChange(index, "unitPrice", e.target.value)
                        }
                        className="bg-gray-200 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                        style={{
                          width: "130px",
                          marginLeft: "10px",
                        }}
                      />
                    </td>

                    <td className="flex justify-end p-2 mr-1">
                      {isNaN(item.total) ? "0.00" : item.total}
                    </td>
                  </tr>
                ))}

               

              </tbody>

            </table>

              <hr />
                <div className="total-info flex space-x-8 justify-end font-bold mr-3 mt-2 mb-2">
                  <p>Total Quantity: {totalQuantity}</p>
                  {/* <p>Total Price: {totalPrice}</p> */}
                  <p>Sub Total: {subTotal.toFixed(2)}</p>
                </div>

                <div className="flex justify-end space-x-10 mb-1">
                  <span>Tax (%):</span>
                  <input
                      type="text"
                      placeholder="0"
                      value={tax}
                      onChange={(e) => handleTaxChange(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 w-32 p-1"
                    />
                </div>

                <div className="flex justify-end space-x-10 mb-1">
                  <span>Fees/Discounts:</span>
                  <input
                    type="text"
                    placeholder="0"
                    value={feesOrDiscounts}
                    onChange={(e) => handleFeesChange(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 w-32 p-1"
                  />
                </div>

                <div className="flex justify-end font-semibold mt-2 ">
                  <span>Total:  {formatNumber(total)}</span>
                </div>
      </div>



        {/* <InvoiceItems
          items={items}
          setItems={setItems}
          total={total}
          setTotal={setTotal}
          subtotal={subtotal}
          setSubtotal={setSubtotal}
          tax={tax}
          setTax={setTax}
          feesOrDiscounts={feesOrDiscounts}
          setFeesOrDiscounts={setFeesOrDiscounts}
          comments={comments}
          termsAndConditions={termsAndConditions}
          // onFocus={() => setIsInputFocused(true)}
          // onBlur={() => setIsInputFocused(false)}
        /> */}

        {/* comments */}
        <div className="sm:col-span-2 mb-8">
          <label
            for="description"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Comments
          </label>
          <textarea
            id="description"
            rows="4"
            name="description"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
            placeholder="Write your Comments here..."
          ></textarea>
        </div>
        <div className="flex justify-between items-center">
          <div className="border-2 border-[#111FFF] py-2 px-5 w-[550px]">
            <h1 className="font-semibold text-blue-500 underline underline-offset-4">
              Terms & Conditions
            </h1>
            <textarea
              id="termsAndConditions"
              rows="4"
              name="termsAndConditions"
              value={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e.target.value)}
              className="block p-2.5 mt-3 mb-3 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
              placeholder="Add your Terms & Conditions here..."
            ></textarea>
          </div>

        




          
              <div>
  {/* Your code for displaying each item */}
  
  {/* Conditionally render the buttons if any item meets the conditions */}
            {shouldRenderButtons && tax !== ""  && (
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => navigate(`/invoice`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                
                >
                  Cancel
                </button>
                <button
                onClick={handleSubmit}
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  disabled={!formField.bill_to}
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
    </div>
  )
}

export default ViewInvoiceForm