import React from "react";
import { Datepicker, Button } from 'flowbite-react';

function Billing() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const customStyles = {
    // Define styles for the dates in the previous month
    '.DayPicker-Day--prevMonth': {
      color: 'gray', // Color of dates in the previous month
    },
    // Define styles for the dates in the next month
    '.DayPicker-Day--nextMonth': {
      color: 'blue', // Color of dates in the next month
    },
  };

  return (
    <div className="flex justify-center items-center flex-col w-full pb-5">
      <Datepicker
        // maxDate={today}
        // minDate={new Date(currentYear, currentMonth, 1)}
        styles={customStyles} // Apply custom styles to the Datepicker component
      />
      <Button>Click me</Button>
    </div>
  );
}

export default Billing;
