


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import axios from "axios";
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline,
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import CustomTooltip from "../forms/CustomeTooltip"
import { MdOutlineBusinessCenter } from "react-icons/md";
import config  from "../../config/config"


function Business() {
  const navigate = useNavigate();
  const [isCardSelected, setIsCardSelected] = useState(false);

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const handleToggle = () => {
    setIsCardSelected(!isCardSelected);
  };
  const CustomTooltip1 = ({ content, show, marginLeft, className }) => {
    return (
      <div className="relative"
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          className:`${className}`,
        
          
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>

        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
 


  // hanlde ZIp code

  const formatZipCode = (value) => {
    const maxLength = 5;

    // Remove any non-digit characters
    const inputValue = value.replace(/\D/g, "");

    // Limit the length to 17 digits
    const processedValue = inputValue.slice(0, maxLength);

    console.log("Processed Input Value:", processedValue);

    return processedValue;
  };

  const handleZipCodeChange = (fieldName, e) => {
    const formattedValue = formatZipCode(e.target.value);
    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };






  function formatPhoneNumber(value) {
    // Remove non-digit characters
    const digitsOnly = value.replace(/\D/g, "");

    // Limit to a maximum of 10 digits
    const limitedDigits = digitsOnly.slice(0, 10);

    // Apply formatting based on the length
    if (limitedDigits.length >= 4 && limitedDigits.length <= 6) {
      return `(${limitedDigits.slice(0, 3)})${limitedDigits.slice(3)}`;
    } else if (limitedDigits.length >= 7) {
      return `(${limitedDigits.slice(0, 3)})${limitedDigits.slice(
        3,
        6
      )}-${limitedDigits.slice(6)}`;
    } else {
      return limitedDigits;
    }
  }

  const handlePhoneNumberChange = (fieldName, e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };

  const validationSchema = Yup.object().shape({
    // businessAccount: Yup.string()
    //   .min(3, "Account No must be at least 3 char's"),
    //   // .required("Account No is required"),

    businessName: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Name should be at least 3 char's"),
      // .required("Business Name is required"),

    businessAddress: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
      // .required("Business Address is required"),
    businessCity: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "City should be at least 3 char's"),
      // .required("Business City is required"),
    businessState: Yup.string(),
    businessZipCode: Yup.string()
      .matches(/^[0-9]{5}$/, "Zip Code must be of 5 digits"),
      // .required("Zip Code is required"),
    contactPersonName: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Name should be at least 3 characters"),
    contactPersonEmail: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Invalid email address"
      ),
    contactPersonPhoneNumber: Yup.string().matches(
      /^\(\d{3}\)\d{3}-\d{4}$/,
      "Invalid phone number format"
    ),
    billingAddress: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
    billingCity: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "City should be at least 3 char's"),
    billingState: Yup.string(),
    billingZipCode: Yup.string().matches(
      /^[0-9]{5}$/,
      "Zip Code must be of 5 digits"
    ),
    shippingTo: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
    shippingAddress: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
    shippingCity: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "City should be at least 3 char's"),
    shippingState: Yup.string(),
    shippingZipCode: Yup.string().matches(
      /^[0-9]{5}$/,
      "Zip Code must be of 5 digits"
    ),
    shippingContactNumber: Yup.string().matches(
      /^\(\d{3}\)\d{3}-\d{4}$/,
      "Invalid phone number format"
    ),
    shippingDeliveryInstructions: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Instructions should be at least 3 char's"),
    bankCurrency: Yup.string(),
    bankRouting: Yup.string().matches(
      /^[0-9]{9}$/,
      "Routing No must be of 9 digits"
    ),
    bankAccount: Yup.string().matches(
      /^[0-9]{17}$/,
      "Account No must be exactly 17 digits"
    ),
    cardNumber: Yup.string().matches(
      /^[0-9]{10,19}$/,
      "Card number must be 10-19 digits"
    ),
    cardExpiryDate: Yup.string().matches(
      /^(0[1-9]|1[0-2])\/[0-9]{2}$/,
      "Invalid date format (MM/YY)"
    ),
    cardCVV: Yup.string().matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits"),
    nameOnCard: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Name on  should be at least 3 characters"),
    creditCardName: Yup.string(),
    cardPaymentCurrency: Yup.string(),
    cardZipCode: Yup.string().matches(
      /^[0-9]{5}$/,
      "Zip Code must be of 5 digits"
    ),
    paymentType: "",
  });

  const formik = useFormik({
    initialValues: {
      businessAccount: "",
      businessName: "",
      businessAddress: "",
      businessCity: "",
      businessState: "",
      businessZipCode: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhoneNumber: "",
      billingAddress: "",
      billingCity: "",
      billingState: "",
      billingZipCode: "",
      shippingTo: "",
      shippingAddress: "",
      shippingCity: "",
      shippingState: "",
      shippingZipCode: "",
      shippingContactNumber: "",
      shippingDeliveryInstructions: "",
      bankCurrency: "",
      bankRouting: "",
      bankAccount: "",
      cardNumber: "",
      cardExpiryDate: "",
      cardCVV: "",
      nameOnCard: "",
      cardZipCode: "",
      paymentType: "",
      creditCardName: "",
      cardPaymentCurrency: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("Form Values:", values);

        const response = await axios.post(
          `${baseURL}/${btoa("business/create")}`,
          values
        );

        console.log("API Response:", response);

        if (response.status === 200) {
          const responseData = await response.data;
          console.log("Response Data:", responseData);

          if (responseData.dataSavingStatus === true) {
            // Array to store the names of filled frames
            const filledFrames = [];

            // Check each frame and add its name to the array
            if (values.businessName) filledFrames.push("Info");
            if (values.contactPersonName) filledFrames.push("Contact Info");
            if (values.billingAddress) filledFrames.push("Billing Info");
            if (values.shippingTo) filledFrames.push("Shipping Info");
            if (values.bankCurrency) filledFrames.push("Payment Info");

            // Construct success message based on filled frames
            let successMessage = `New ${values.businessName}`;

            if (filledFrames.length > 0) {
              successMessage += ` ${filledFrames.join(
                ", "
              )} was added successfully`;
            }

            showToast(
              successMessage,
              "success",
              values.businessName,
              filledFrames
            );
            navigate(`/`);
          } else {
            showToast("Unable to add new customer, please try again", "error");
          }
        } else {
          console.log("Request failed with status:", response.status);
          showToast("Unable to add new customer, please try again", "error");
        }
      } catch (error) {
        console.error("API Error:", error);
        console.log("Error Details:", error.response);

        showToast("Unable to add new customer, please try again", "error");
      }
    },
  });

  const showToast = (message, type, businessName, filledFrames) => {
    let toastColorClass;
    let iconComponent;

    if (type === "success") {
      toastColorClass = "bg-green-50 text-green-500";
      iconComponent = (
        <FaCheckCircle className="mr-6 text-[#2BDE3F]" size={24} />
      );
    } else {
      toastColorClass = "bg-red-50 text-red-500";
      iconComponent = <FaTimesCircle className="mr-6 text-red-500" size={24} />;
    }

    toast.error(
      <div className={`flex justify-between  ${toastColorClass}`}>
        <div className="rounded-lg" style={{ whiteSpace: "nowrap" }}>
          {message}
        </div>
        <div className="rounded-lg ">{iconComponent}</div>
      </div>,
      {
        position: "top-left",
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: "w-[843px] ml-[240px] mt-[60px]", // Full width
        style: {
          borderLeft:
            type === "success" ? "5px solid #2BDE3F" : "5px solid red",
          background: type === "success" ? "#f3faf7" : "#fdf2f2",
          minHeight: "10px",
        },
      }
    );
  };

  const areRequiredBusinessFilled = () => {
    return (
      formik.values.businessName &&
      // formik.values.businessAccount &&
      formik.values.businessAddress &&
      formik.values.businessCity &&
      formik.values.businessState &&
      formik.values.businessZipCode
    );
  };
  const areRequiredContactFilled = () => {
    return (
      formik.values.contactPersonName &&
      formik.values.contactPersonEmail &&
      formik.values.contactPersonPhoneNumber
    );
  };
  const areRequiredBillingFilled = () => {
    return (
      formik.values.billingAddress &&
      formik.values.billingCity &&
      formik.values.billingState &&
      formik.values.billingZipCode
    );
  };


  return (
    <div className="pt-20 pb-20">
      <h1 className="mx-8 font-bold mb-24 text-gray-400 text-lg mt-[-32px]">
        Business {">>"} New Business
      </h1>

      <form onSubmit={formik.handleSubmit}>
        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">

          <div className="flex items-center">
            <MdOutlineBusinessCenter className="text-gray-700 w-6 h-6 mr-2 mb-10" />
            <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
              New Business Info
            </h2>
          </div>

          <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
           
          <div className="w-full">

             
                <label
                htmlFor="businessName"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Business Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessName}
                show={!!formik.errors.businessName}
              />
             

              <input
                type="text"
                id="businessName"
                name="businessName"
                value={formik.values.businessName}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Remove non-alphabetic characters and spaces
                  const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");

                  // Capitalize the first letter of each word
                  const capitalizedValue = sanitizedValue
                    .toLowerCase()
                    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

                  formik.handleChange({
                    target: { name: "businessName", value: capitalizedValue },
                  });
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.businessName && formik.errors.businessName
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Business Name"
              />
              {/* {formik.touched.businessName && formik.errors.businessName && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.businessName}
                </div>
              )} */}
            </div>


            <div className="w-full">
              <label
                htmlFor="businessAddress"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Business Address{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessAddress}
                show={!!formik.errors.businessAddress}
              
              />
              <input
                type="text"
                id="businessAddress"
                name="businessAddress"
                value={formik.values.businessAddress}
                onChange={(e) => {
                  const inputValue = e.target.value;
              
                  // Allow only commas, numbers, and text
                  const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');
              
                  // Capitalize the first letter of each word
                  const capitalizedValue = sanitizedValue
                    .toLowerCase()
                    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
              
                  formik.handleChange({
                    target: {
                      name: "businessAddress",
                      value: capitalizedValue,
                    },
                  });
                }}
                
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.businessAddress &&
                  formik.errors.businessAddress
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Business Address"
              />
              {/* {formik.touched.businessAddress &&
                formik.errors.businessAddress && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.businessAddress}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessCity"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                City{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessCity}
                show={!!formik.errors.businessCity}
              
              />
              <input
                type="text"
                id="businessCity"
                name="businessCity"
                className={`${
                  formik.touched.businessCity && formik.errors.businessCity
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter City"
                value={formik.values.businessCity}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Remove non-alphabetic characters and spaces
                  const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");

                  // Capitalize the first letter of each word
                  const capitalizedValue = sanitizedValue
                    .toLowerCase()
                    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

                  formik.handleChange({
                    target: { name: "businessCity", value: capitalizedValue },
                  });
                }}
                onBlur={formik.handleBlur}
              />
              {/* {formik.touched.businessCity && formik.errors.businessCity && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.businessCity}
                </div>
              )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessState"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                State{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessState}
                show={!!formik.errors.businessState}
              
              />
              <select
                id="businessState"
                name="businessState"
                className={`${
                  formik.touched.businessState && formik.errors.businessState
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                value={formik.values.businessState}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select State</option>
                <option value="AL">Alabama (AL)</option>
                <option value="AK">Alaska (AK)</option>
                <option value="AZ">Arizona (AZ)</option>
                <option value="AR">Arkansas (AR)</option>
                <option value="CA">California (CA)</option>
                <option value="CO">Colorado (CO)</option>
                <option value="CT">Connecticut (CT)</option>
                <option value="DE">Delaware (DE)</option>
                <option value="FL">Florida (FL)</option>
                <option value="GA">Georgia (GA)</option>
                <option value="HI">Hawaii (HI)</option>
                <option value="ID">Idaho (ID)</option>
                <option value="IL">Illinois (IL)</option>
                <option value="IN">Indiana (IN)</option>
                <option value="IA">Iowa (IA)</option>
                <option value="KS">Kansas (KS)</option>
                <option value="KY">Kentucky (KY)</option>
                <option value="LA">Louisiana (LA)</option>
                <option value="ME">Maine (ME)</option>
                <option value="MD">Maryland (MD)</option>
                <option value="MA">Massachusetts (MA)</option>
                <option value="MI">Michigan (MI)</option>
                <option value="MN">Minnesota (MN)</option>
                <option value="MS">Mississippi (MS)</option>
                <option value="MO">Missouri (MO)</option>
                <option value="MT">Montana (MT)</option>
                <option value="NE">Nebraska (NE)</option>
                <option value="NV">Nevada (NV)</option>
                <option value="NH">New Hampshire (NH)</option>
                <option value="NJ">New Jersey (NJ)</option>
                <option value="NM">New Mexico (NM)</option>
                <option value="NY">New York (NY)</option>
                <option value="NC">North Carolina (NC)</option>
                <option value="ND">North Dakota (ND)</option>
                <option value="OH">Ohio (OH)</option>
                <option value="OK">Oklahoma (OK)</option>
                <option value="OR">Oregon (OR)</option>
                <option value="PA">Pennsylvania (PA)</option>
                <option value="RI">Rhode Island (RI)</option>
                <option value="SC">South Carolina (SC)</option>
                <option value="SD">South Dakota (SD)</option>
                <option value="TN">Tennessee (TN)</option>
                <option value="TX">Texas (TX)</option>
                <option value="UT">Utah (UT)</option>
                <option value="VT">Vermont (VT)</option>
                <option value="VA">Virginia (VA)</option>
                <option value="WA">Washington (WA)</option>
                <option value="WV">West Virginia (WV)</option>
                <option value="WI">Wisconsin (WI)</option>
                <option value="WY">Wyoming (WY)</option>
              </select>
              {/* {formik.touched.businessState && formik.errors.businessState && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.businessState}
                </div>
              )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessZipCode"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Zip Code{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessZipCode}
                show={!!formik.errors.businessZipCode}
               
              />
              <input
                className={`${
                  formik.touched.businessZipCode &&
                  formik.errors.businessZipCode
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Zipcode"
                type="text"
                id="businessZipCode"
                name="businessZipCode"
                value={formik.values.businessZipCode}
                onChange={(e) => handleZipCodeChange("businessZipCode", e)}
                onBlur={formik.handleBlur}
              />
              {/* {formik.touched.businessZipCode &&
                formik.errors.businessZipCode && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.businessZipCode}
                  </div>
                )} */}
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            {areRequiredBusinessFilled() && (
              <>
                <button
                  onClick={() => navigate(`/business`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>

        {/* customer */}
        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
          <div className="flex items-center">
            <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
            <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
              Business Contact Info
            </h2>
          </div>
          <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="contactPersonName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Contact Name
              </label>
              <CustomTooltip1
                content={formik.errors.contactPersonName}
                show={!!formik.errors.contactPersonName}
                marginLeft={120}
              />
              <input
                type="text"
                id="contactPersonName"
                name="contactPersonName"
                value={formik.values.contactPersonName}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Remove non-alphabetic characters and spaces
                  const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");

                  // Capitalize the first letter of each word
                  const capitalizedValue = sanitizedValue
                    .toLowerCase()
                    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

                  formik.handleChange({
                    target: {
                      name: "contactPersonName",
                      value: capitalizedValue,
                    },
                  });
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.contactPersonName &&
                  formik.errors.contactPersonName
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Name"
              />
              {/* {formik.touched.contactPersonName &&
                formik.errors.contactPersonName && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.contactPersonName}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="contactPersonEmail"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email
              </label>
              <CustomTooltip1
                content={formik.errors.contactPersonEmail}
                show={!!formik.errors.contactPersonEmail}
                marginLeft={206}
              />
              <input
                type="text"
                id="contactPersonEmail"
                name="contactPersonEmail"
                value={formik.values.contactPersonEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.contactPersonEmail &&
                  formik.errors.contactPersonEmail
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Email"
              />
              {/* {formik.touched.contactPersonEmail &&
                formik.errors.contactPersonEmail && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.contactPersonEmail}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="contactPersonPhoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Phone Number
              </label>
              <CustomTooltip1
                content={formik.errors.contactPersonPhoneNumber}
                show={!!formik.errors.contactPersonPhoneNumber}
                marginLeft={163}
              />
              <input
                type="tel"
                id="contactPersonPhoneNumber"
                name="contactPersonPhoneNumber"
                value={formik.values.contactPersonPhoneNumber}
                onChange={(e) =>
                  handlePhoneNumberChange("contactPersonPhoneNumber", e)
                }
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.contactPersonPhoneNumber &&
                  formik.errors.contactPersonPhoneNumber
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Phone Number"
              />
              {/* {formik.touched.contactPersonPhoneNumber &&
                formik.errors.contactPersonPhoneNumber && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.contactPersonPhoneNumber}
                  </div>
                )} */}
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            {areRequiredContactFilled() && (
              <>
                <button
                  onClick={() => navigate(`/customers`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>

      

       

         
          
        
       
      </form>
    </div>
  );
}

export default Business;
