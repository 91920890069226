import React, { useState, useEffect  } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline,
} from "react-icons/md";
import UserPasswordChangeModal from '../pages/UserPasswordChangeModal';
import { TbUserStar } from 'react-icons/tb';
import { toast } from "react-toastify";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { MdOutlineBusinessCenter } from "react-icons/md";
import config  from "../../config/config"


const ViewBusinessUsers = () => {

  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  console.log("user_role" , user_role);
  console.log("bus id is " , business_id);
  console.log("user id is " , user_id);

  const navigate = useNavigate()
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [contactInfoEditMode, setContactInfoEditMode] = useState(false);

  const [managerData , setManagerData ] = useState([])
    const[ownerData , setOwnerData] = useState([])  

  const [contactNameError, setContactNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [inputPhoneError, setInputPhoneError] = useState("");


  const handleOpenModal = () => {
    console.log("Button clicked'");
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; 
  };

  const handleCloseModal = () => {
    console.log('Modal closed');
    setIsModalOpen(false);
    document.body.style.overflow = '';
  };

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [userData, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    contact_no: "",
  });

  const handleContactNameChange = (e) => {                          // Contact name setting and validation
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setUserData({
      ...userData,
      [name]: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setContactNameError(" Name must be at least 3 letters");
    } else {
      setContactNameError("");
    }
      
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const isValidEmail = validateEmail(value);

    setUserData({
      ...userData,
      [name]: value,
    });
    if (value.length > 0 && !isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }

  };

  const handleInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }
    setUserData({
      ...userData,
      [name]: inputValue,
    });
    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
  }

  const filteredOwnersData = ownerData.filter((owner) => owner.contact_email !== userData.contact_email);

  
  const handleUserEditClick = async ()=>{

    try{
      const response = await axios.put(`${baseURL}/${btoa("manager/update/")}` , {
          edit_managerId: user_id,
          email_db : localStorage.getItem("userEmail"),
          edit_contactPersonName: userData.contact_name,
          edit_contactPersonEmail: userData.contact_email,
          edit_contactPersonPhoneNumber: userData.contact_no
      })
            console.log("1" ,localStorage.getItem("userEmail") );
            console.log("2" ,userData.contact_name );
            console.log("3" ,userData.contact_email );
            console.log("4" ,userData.contact_no );
             console.log("owner data posting " , response);
            //  navigate("/")
            setContactInfoEditMode(!contactInfoEditMode);
              let successMessage = `${userData.contact_name}, details updated successfully`;
              showToast(
              successMessage,
              "success",
            );
    }catch(error){
      console.log("error posting owner details" , error);
  }
}


    const [businessData, setBusinessData] = useState({
          business_name : "",
          business_address : "",
          business_city : "",
          business_state : "",
          business_zip_code : ""
  });

 

          
   

 

  useEffect(() => {
    axios
      .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log("response" , response);

        const business = response.data.business_data[0]
        setBusinessData({
          business_name : business.business_name,
          business_address : business.business_address,
          business_city : business.business_city,
          business_state : business.business_state,
          business_zip_code : business.business_zip_code
        })

        const user = response.data.manager_data[0]
        setUserData({    
          contact_name: user.contact_name,
          contact_email: user.contact_email,
          contact_no: user.contact_no,
        });

        
        setOwnerData(response.data.owner_data)

        
        setManagerData(businessData)
      })
      .catch((error) => {
        console.error("Error fetching managers data:", error);
       
      });
  }, []);

  console.log("business details after setting" , businessData);
  console.log("owner details after setting" , ownerData);
  console.log("user details after setting" , userData);


  const showToast = (message, type, businessName, filledFrames) => {
    let toastColorClass;
    let iconComponent;

    if (type === "success") {
      toastColorClass = "bg-blue-50 text-blue-500";
      iconComponent = (
        <FaCheckCircle className="mr-6 text-[#1D72F3] bg-blue-50" size={24} />
      );
    } else {
      toastColorClass = "bg-red-50 text-red-500";
      iconComponent = <FaTimesCircle className="mr-6 text-red-500" size={24} />;
    }

    toast.error(
      <div className={`flex justify-between ${toastColorClass}`}>
        <div className="rounded-lg" style={{ whiteSpace: "nowrap" }}>
          {message}
        </div>
        <div className="rounded-lg  ">{iconComponent}</div>
      </div>,
      {
        position: "top-left", 
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: " w-[843px] ml-[240px] mt-[60px]", // Full width
        style: {
          borderLeft:
            type === "success" ? "5px solid #1D72F3" : "5px solid red",
          background: type === "success" ? "#ebf5ff" : "#fdf2f2",
          minHeight: "10px",
          
        },
      }
    );
  };

  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };



   
  return (
    <div>

      <div>
          <h1 className="mx-8 font-bold text-gray-400 text-xl mt-10 mb-10">
                     User Contact Info
          </h1>
      </div>
        
          <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">
            <div className="flex items-center">
              <MdOutlineBusinessCenter className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                 Business Info
              </h2>
            </div>
            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              
              <div class="w-full">
                <label
                  htmlFor="businessName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Business Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>

                {/* <CustomTooltip1 content={busNameError} show={!!busNameError}   marginLeft={140}/> */}

                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={businessData.business_name}
                  // onChange={handlebusNameChange}
                  required
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter Business Name"
                  readOnly
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="businessAddress"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Business Address{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                {/* <BusinessAddressTooltip
                  content="Business Address should be at least three char."
                  show={formData.businessAddress.length < 3}
                  marginLeft={140}
                /> */}

                <input
                  type="text"
                  id="businessAddress"
                  name="businessAddress"
                  value={businessData.business_address}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                
                    // Allow only commas, numbers, and text
                    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');
                
                    // Update the state with the sanitized value
                    setBusinessData((prevData) => ({
                      ...prevData,
                      business_address: sanitizedValue,
                    }));
                  }}
                  required
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter Business Address"
                  readOnly
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessCity"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  City{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                {/* <CustomTooltip1
                  content={busCityNameError}
                  show={!!busCityNameError}
                  marginLeft={155}
                /> */}

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter City"
                  type="text"
                  id="businessCity"
                  name="businessCity"
                  value={businessData.business_city}
                  // onChange={handlebusCityChange}
                  required
                  readOnly
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessState"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  State{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <select
                  id="businessState"
                  name="businessState"
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  value={businessData.business_state}
                  // onChange={handleInputChange}
                  // onChange={handleChange}
                  required
                  // disabled={!businessInfoEditMode}
                  readOnly
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessZipCode"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Zip Code{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                {/* <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/> */}

                <input
                   className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter Zipcode"
                  type="text"
                  id="businessZipCode"
                  name="businessZipCode"
                  value={businessData.business_zip_code}
                  // onChange={handleInputZipChange}
                  required
                  readOnly
                />
                {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
              </div>
            </div>
            </div>

        
            <div>
          {filteredOwnersData.map((owner , index) => (
          <div key={owner.id} className="w-auto bg-white rounded-box mx-8 rounded-xl mt-16">
              
              <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Business Owner Contact Info
              </h2>
             </div>

          <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="contactPersonName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                </label>
                {/* <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                /> */}

                <input
                  className={`border border-gray-300 mb-6 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id={`contactPersonName-${index}`}
                  name={`contactPersonName-${index}`}
                  value={owner.contact_name}
                  // onChange={handleContactNameChange}
                  required
                  readOnly
                />
              </div>



            <div class="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                </label>
                {/* <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/> */}

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id={`contactPersonEmail-${index}`}
                  name={`contactPersonEmail-${index}`}
                  value={owner.contact_email}
                  // onChange={handleEmailChange}
                  required
                  readOnly
                />
                {/* {emailError && (
                  <div className="text-red-500 text-sm mt-1">{emailError}</div>
                )} */}
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  class="block mb-2 text-sm font-medium text-gray-900  "
                >
                  Phone Number
                </label>
                {/* <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                /> */}

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id={`contactPersonPhoneNumber-${index}`}
                  name={`contactPersonPhoneNumber-${index}`}
                  value={owner.contact_no}
                  // onChange={handleInputPhoneChange}
                  required
                  readOnly
                />
              
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              {/* <>
                
              <button
                onClick={() => {
                  if (contactInfoEditMode) {
                    handleEditClick();
                  } else {
                    setContactInfoEditMode(!contactInfoEditMode);
                  }
                }}
            type="submit"
                className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (contactInfoEditMode) {
                    handleEditClick();
                  } else {
                    setContactInfoEditMode(!contactInfoEditMode);
                  }
                }}
                type="submit"
               
                className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                {contactInfoEditMode ? "Save" : "Edit"}
              </button>
            </> */}
          </div>
        </div>
      ))}
         </div>


        <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16 mb-6">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Business User Contact Info
              </h2>
            </div>

            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="contactPersonName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id="contact_name"
                  name="contact_name"
                  value={userData.contact_name}
                  onChange={handleContactNameChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/>

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id="contact_email"
                  name="contact_email"
                  value={userData.contact_email}
                  onChange={handleEmailChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  class="block mb-2 text-sm font-medium text-gray-900  "
                >
                  Phone Number
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id="contact_no"
                  name="contact_no"
                  value={userData.contact_no}
                  onChange={handleInputPhoneChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
              </div>
            </div>



            <div className="flex justify-end space-x-3">
            
                <>
                  <button
                    onClick={()=> navigate("/")}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>

                  {/* <button
                      onClick={() => {
                        if (contactInfoEditMode) {
                          // Perform save logic here
                          handleUserEditClick();
                        } else {
                          // Toggle edit mode
                          setContactInfoEditMode(!contactInfoEditMode);
                        }
                      }}
                    type="submit"
                    disabled={
                      userData.contact_name.length < 3 || 
                      userData.contact_email.length < 3 ||
                      !userData.contact_email.includes('@') ||
                      !userData.contact_email.includes('.') || 
                      userData.contact_no.length < 13 ||
                      emailError

                    // Add additional conditions for other business info fields if needed
                  }
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    {contactInfoEditMode ? "Save" : "Edit"}
                  </button> */}

                  <button
                    type="submit"
                    onClick={handleOpenModal}
                     className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  >
                    change password
                  </button>
                  {isModalOpen && (
                    <UserPasswordChangeModal onClose={handleCloseModal} />
                  )}
                </>
           
            </div>
          </div>


    </div>
  )
}

export default ViewBusinessUsers