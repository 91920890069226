import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTabs } from "../pages/TabContext";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
// import * as Yup from "yup";
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline,
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
// import CustomTooltip from "./CustomeTooltip";
import { MdOutlineBusinessCenter } from "react-icons/md";
import OwnerPasswordChangeModal from "../pages/OwnerPasswordChangeModal";
import config  from "../../config/config"


const ViewBusiness = () => {
  const { changeTab } = useTabs();
  const handleCancel = () => {
    // Change tab to 'items'
    navigate(`/`); // Navigate to the 'items' route
  };

  const { businessId } = useParams();
  console.log(businessId);

  const [isFormModified, setIsFormModified] = useState(false);
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const BusinessAddressTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  

  const navigate = useNavigate();
  const [customerDetails, setCustomerDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [customerEditMode, setCustomerEditMode] = useState(false);
  const [billingEditMode, setBillingEditMode] = useState(false);
  const [shippingEditMode, setShippingEditMode] = useState(false);
  const [paymentEditMode, setPaymentEditMode] = useState(false);

  const [billingZipCodeError, setBillingZipCodeError] = useState("");
  const [shippingZipCodeError, setShippingZipCodeError] = useState("");
  const [cardZipCodeError, setCardZipCodeError] = useState("");
  const [bankAccountError, setBankAccountError] = useState("");
  const [businessAccountError, setBusinessAccountError] = useState("");
  const [inputPhoneError, setInputPhoneError] = useState("");
  const [bankRoutingError, setBankRoutingError] = useState("");
 
  const [emailError, setEmailError] = useState("");

  const [isBillingInfoModified, setIsBillingInfoModified] = useState(false);
  const [isShippingInfoModified, setIsShippingInfoModified] = useState(false);
  const [isPaymentInfoModified, setIsPaymentInfoModified] = useState(false);

  const [busNameError, setbusNameError] = useState("");
  const [busAddNameError, setBusAddNameError] = useState("");
  const [busCityNameError, setbusCityNameError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  

  const [contactNameError, setContactNameError] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [isBusinessInfoModified, setIsBusinessInfoModified] = useState(false);
  const [isContactInfoModified, setIsContactInfoModified] = useState(false);

  const [businessInfoEditMode, setBusinessInfoEditMode] = useState(false);
  const [contactInfoEditMode, setContactInfoEditMode] = useState(false);

  const [managersData, setManagersData] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [ownerId , setOwnerId] = useState()

  const handleOpenModal = () => {
    console.log("Button clicked'");
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; 
  };

  const handleCloseModal = () => {
    console.log('Modal closed');
    setIsModalOpen(false);
    document.body.style.overflow = '';
  };

  const handlebusNameChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });
    console.log(formData);

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusNameError("Name must be at least 3 letters");
    } else {
      setbusNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };
  const handlebusAddChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBusAddNameError("Address must be at least 3 letters");
    } else {
      setBusAddNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };
  const handlebusCityChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusCityNameError(" City Name must be at least 3 letters");
    } else {
      setbusCityNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };


  const[ownerData , setOwnerData] = useState({   
    edit_ownerId : ownerId,         // OWNER DATA SETTING FROM API
    contact_name : "",
    contact_email : "",
    contact_no : "",
  })

  const handleContactNameChange = (e) => {
    const { name, value } = e.target;

    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setOwnerData({
      ...ownerData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setContactNameError(" Name must be at least 3 letters");
    } else {
      setContactNameError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
  };


  const [formData, setFormData] = useState({
    businessName: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZipCode: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonPhoneNumber: "",
   
  });

  console.log(formData);

 

  const [isCardSelected, setIsCardSelected] = useState(false);
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const validateEmail = (email) => {
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    // Validate email
    const isValidEmail = validateEmail(value);

    setOwnerData({
      ...ownerData,
      [name]: value,
    });

    // Set error message if email is not in the expected format
    if (value.length > 0 && !isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
  };

  const showToast = (message, type, businessName, filledFrames) => {
    let toastColorClass;
    let iconComponent;

    if (type === "success") {
      toastColorClass = "bg-blue-50 text-blue-500";
      iconComponent = (
        <FaCheckCircle className="mr-6 text-[#1D72F3] bg-blue-50" size={24} />
      );
    } else {
      toastColorClass = "bg-red-50 text-red-500";
      iconComponent = <FaTimesCircle className="mr-6 text-red-500" size={24} />;
    }

    toast.error(
      <div className={`flex justify-between ${toastColorClass}`}>
        <div className="rounded-lg" style={{ whiteSpace: "nowrap" }}>
          {message}
        </div>
        <div className="rounded-lg  ">{iconComponent}</div>
      </div>,
      {
        position: "top-left", 
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: " w-[843px] ml-[240px] mt-[60px]", // Full width
        style: {
          borderLeft:
            type === "success" ? "5px solid #1D72F3" : "5px solid red",
          background: type === "success" ? "#ebf5ff" : "#fdf2f2",
          minHeight: "10px",
          
        },
      }
    );
  };

  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  // console.log("user role is" , user_role);
  // console.log("bus id is " , business_id);
  // console.log("user id is " , user_id);


  
  useEffect(() => {
    axios
      .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log(response);
        const businessData = response.data.business_data[0];
        console.log(businessData);
        // setCustomerDetails(businessData);
        setFormData({
          businessId : businessData.business_id,
          businessName: businessData.business_name,
          businessAddress: businessData.business_address,
          businessCity: businessData.business_city,
          businessState: businessData.business_state,
          businessZipCode: businessData.business_zip_code,
          contactPersonName: businessData.contact_name,
          contactPersonEmail: businessData.contact_email,
          contactPersonPhoneNumber: businessData.contact_no,
        
        });

        const ownerData = response.data.owner_data[0];
        setOwnerData({
            contact_name : ownerData.contact_name,
            contact_email : ownerData.contact_email,
            contact_no : ownerData.contact_no
        })

       
        setManagersData(response.data.managers_list) 

        // console.log("owners data " , ownerData);
        // console.log("managers data" , managersData);  
        setOwnerId(response.data.business_data[0].owner_id)  

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false);
      });
  }, [businessId]);

 

  console.log("managers data" , managersData);

  

  const handleInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);

    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }

    setOwnerData({
      ...ownerData,
      [name]: inputValue,
    });

    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid number");
    } else {
      setInputPhoneError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
    const isShippingModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsShippingInfoModified(isShippingModified);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const transformedValue = [
      "businessState",
      "billingState",
      "shippingState",
      "bankCurrency",
      "cardPaymentCurrency",
    ].includes(name)
      ? value
      : capitalizeWords(value);

    setFormData({
      ...formData,
      [name]: transformedValue,
    });
    
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
      
  };


  const processBusinessAccountInput = (value) => {
    // Remove any characters that are not letters, numbers, or spaces
    const inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");

    // Capitalize the first letter of each word
    const processedValue = inputValue
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

    return processedValue;
  };



  

  const handleInputZipChange = (e) => {
    const { name, value } = e.target;

    // Validate zip code to be at most 5 digits
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 5); // Limit to 5 digits

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
      setZipCodeError("Zip code must be at most 5 digits");
    } else {
      setZipCodeError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };


  const handleEditClick = async (e) => {
    if (
      zipCodeError ||
      billingZipCodeError ||
      shippingZipCodeError ||
      cardZipCodeError ||
      bankAccountError ||
      businessAccountError ||
      inputPhoneError
    ) {
      // Display an error message or take appropriate action
      return;
    }
    const updatedData = {
      
      edit_businessName: formData.businessName,
      edit_businessId : formData.businessId,
      edit_businessAddress: formData.businessAddress,
      edit_businessCity: formData.businessCity,
      edit_businessState: formData.businessState,
      edit_businessZipCode: formData.businessZipCode,
      edit_contactPersonName: formData.contactPersonName,
      edit_contactPersonEmail: formData.contactPersonEmail,
      edit_contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
     
    };
    // ... (Rest of your code)
    console.log( "updated data :" , updatedData);

    try {
      const response = await axios.put(
        `${baseURL}/${btoa("business/update/")}`,
        updatedData
      );
      console.log("updated Data : " , updatedData  );

      console.log("Update Response:", response); // Add this log to check the response

      if (response.status === 200) {
        const responseData = await response.data;

        if (responseData.dataUpdatingStatus === true) {
          const filledFrames = [];

          // Check each frame and add its name to the array
          if (formData.businessName) filledFrames.push("Info");
          if (formData.contactPersonName) filledFrames.push("Contact Info");
          if (formData.billingAddress) filledFrames.push("Billing Info");
          if (formData.shippingTo) filledFrames.push("Shipping Info");
          if (formData.bankCurrency) filledFrames.push("Payment Info");

          let successMessage = `${formData.businessName}`;

          if (filledFrames.length > 0) {
            successMessage += ` updated successfully`;
          }

          showToast(
            successMessage,
            "success",
            formData.businessName,
            filledFrames
          );
          // Clear form data after a successful submission
          // You can call a function to reset form values here if needed
        } else {
          showToast(
            `Unable to update ${formData.businessName} customer data`,
            "error"
          );
        }
      } else {
        console.log("Request failed with status: " + response.status);
        response.data.then((data) => {
          console.log(data);
        });
        showToast("Unable to update customer, please try again", "error");
      }
    } catch (error) {
      console.error("Update Error:", error);
      showToast("Unable to update customer, please try again", "error");
    }

    window.scrollTo(0, 0);
    navigate(`/`);
  };

  console.log("Form Data:", formData);
  
   // Owner data sending

   console.log(ownerId);
   
   const ownerUpdatedData = {
        edit_ownerId : user_id,
        email_db : localStorage.getItem("userEmail"),
        edit_contactPersonName: ownerData.contact_name,
        edit_contactPersonEmail: ownerData.contact_email,
        edit_contactPersonPhoneNumber: ownerData.contact_no,
  };

  const handleOwnerEditClick = async ()=>{
        try{
          const response = await axios.put(`${baseURL}/${btoa("owner/update/")}` , ownerUpdatedData)
                  console.log("owner data is" , ownerUpdatedData);
                console.log("owner data posting " , response);
                // navigate("/")
                setContactInfoEditMode(!contactInfoEditMode);
                let successMessage = `${ownerData.contact_name}, details updated successfully`;
                  showToast(
                  successMessage,
                  "success",
                );
        }catch(error){
          console.log("error posting owner details" , error);
        }
  }

  
        

  
  const handleToggle = () => {
    setIsCardSelected(!isCardSelected);
  };

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Usage example
  const businessInfoFields = [
    "businessName",    
    "businessAddress",
    "businessCity",
    "businessState",
    "businessZipCode",
  ];
  const contactInfoFields = [
    "contactPersonName",
    "contactPersonEmail",
    "contactPersonPhoneNumber",
  ];
  
  const filteredManagersData = managersData.filter((manager) => manager.contact_email !== ownerData.contact_email);



  return (
    <div>
      {loading ? (
        <p>Loading business details...</p>
      ) : (
        <div className="pt-20 pb-20">
          <h1 className="mx-8 font-bold mb-24 text-gray-400 text-xl mt-[-34px]">
            Owner Contact Info
          </h1>

          <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">
            <div className="flex items-center">
              <MdOutlineBusinessCenter className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                 Business Info
              </h2>
            </div>
            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              
              <div class="w-full">
                <label
                  htmlFor="businessName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Business Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>

                <CustomTooltip1 content={busNameError} show={!!busNameError}   marginLeft={140}/>

                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handlebusNameChange}
                  required
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    businessInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Business Name"
                  readOnly={!businessInfoEditMode}
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="businessAddress"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Business Address{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <BusinessAddressTooltip
                  content="Business Address should be at least three char."
                  show={formData.businessAddress.length < 3}
                  marginLeft={140}
                />

                <input
                  type="text"
                  id="businessAddress"
                  name="businessAddress"
                  value={formData.businessAddress}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                
                    // Allow only commas, numbers, and text
                    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');
                
                    // Update the state with the sanitized value
                    setFormData((prevData) => ({
                      ...prevData,
                      businessAddress: sanitizedValue,
                    }));
                  }}
                  required
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    businessInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Business Address"
                  readOnly={!businessInfoEditMode}
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessCity"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  City{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={busCityNameError}
                  show={!!busCityNameError}
                  marginLeft={155}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    businessInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter City"
                  type="text"
                  id="businessCity"
                  name="businessCity"
                  value={formData.businessCity}
                  onChange={handlebusCityChange}
                  required
                  readOnly={!businessInfoEditMode}
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessState"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  State{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <select
                  id="businessState"
                  name="businessState"
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    businessInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  value={formData.businessState}
                  onChange={handleInputChange}
                  // onChange={handleChange}
                  required
                  disabled={!businessInfoEditMode}
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessZipCode"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Zip Code{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/>

                <input
                   className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    businessInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Zipcode"
                  type="text"
                  id="businessZipCode"
                  name="businessZipCode"
                  value={formData.businessZipCode}
                  onChange={handleInputZipChange}
                  required
                  readOnly={!businessInfoEditMode}
                />
                {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
              </div>
            </div>
                                  
            {/* <div className="flex justify-end space-x-3">
            
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    // onClick={handleEditClick}
                    onClick={() => {
                      if (businessInfoEditMode) {
                        // Perform save logic here
                        handleEditClick();
                      } else {
                        // Toggle edit mode
                        setBusinessInfoEditMode(!businessInfoEditMode);
                      }
                    }}
                    type="submit"
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      formData.businessName.length < 3 ||
                      formData.businessAddress.length < 3 ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === '' || 
                      formData.businessZipCode.length < 3
                    }
                  >
                     {businessInfoEditMode ? "Save" : "Edit"}
                  </button>
                </>
           
            </div> */}

          </div>

          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Business Owner Contact Info
              </h2>
            </div>

            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="contactPersonName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                  <span className="text-red-500 text-xl font-extrabold  ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id="contact_name"
                  name="contact_name"
                  value={ownerData.contact_name}
                  onChange={handleContactNameChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                  <span className="text-red-500 text-xl font-extrabold  ml-1">*</span>
                </label>
                <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/>

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id="contact_email"
                  name="contact_email"
                  value={ownerData.contact_email}
                  onChange={handleEmailChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
                {/* {emailError && (
                  <div className="text-red-500 text-sm mt-1">{emailError}</div>
                )} */}
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  class="block mb-2 text-sm font-medium text-gray-900  "
                >
                  Phone Number
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id="contact_no"
                  name="contact_no"
                  value={ownerData.contact_no}
                  onChange={handleInputPhoneChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
                {/* {inputPhoneError && (
                  <div className="text-red-500 text-sm mt-1">
                    {inputPhoneError}
                  </div>
                )} */}
              </div>
            </div>



            <div className="flex justify-end space-x-3">
            
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>

                  {/* <button
                      onClick={() => {
                        if (contactInfoEditMode) {
                          // Perform save logic here
                          handleOwnerEditClick();
                        } else {
                          // Toggle edit mode
                          setContactInfoEditMode(!contactInfoEditMode);
                        }
                      }}
                    type="submit"
                    disabled={
                      ownerData.contact_name.length < 3 || 
                      ownerData.contact_email.length < 3 ||
                      !ownerData.contact_email.includes('@') ||
                      !ownerData.contact_email.includes('.') || 
                      ownerData.contact_no.length < 13 ||
                      emailError
                    // Add additional conditions for other business info fields if needed
                  }
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    {contactInfoEditMode ? "Save" : "Edit"}
                  </button> */}

                  <button
                    type="submit"
                    onClick={handleOpenModal}
                     className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  >
                    change password
                  </button>
                  {isModalOpen && (
                    <OwnerPasswordChangeModal onClose={handleCloseModal} />
                  )}
                </>
           
            </div>
          </div>

              
          <div>
          {filteredManagersData.map((manager , index) => (
          <div key={manager.id} className="w-auto bg-white rounded-box mx-8 rounded-xl mt-16">
              
              <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Business User Contact Info
              </h2>
             </div>

          <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="contactPersonName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                </label>
                {/* <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                /> */}

                <input
                  className={`border border-gray-300 mb-6 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id={`contactPersonName-${index}`}
                  name={`contactPersonName-${index}`}
                  value={manager.contact_name}
                  onChange={handleContactNameChange}
                  required
                  readOnly
                />
              </div>



            <div class="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                </label>
                {/* <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/> */}

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id={`contactPersonEmail-${index}`}
                  name={`contactPersonEmail-${index}`}
                  value={manager.contact_email}
                  onChange={handleEmailChange}
                  required
                  readOnly
                />
                {/* {emailError && (
                  <div className="text-red-500 text-sm mt-1">{emailError}</div>
                )} */}
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  class="block mb-2 text-sm font-medium text-gray-900  "
                >
                  Phone Number
                </label>
                {/* <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                /> */}

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id={`contactPersonPhoneNumber-${index}`}
                  name={`contactPersonPhoneNumber-${index}`}
                  value={manager.contact_no}
                  onChange={handleInputPhoneChange}
                  required
                  readOnly
                />
              
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              {/* <>
                
              <button
                onClick={() => {
                  if (contactInfoEditMode) {
                    handleEditClick();
                  } else {
                    setContactInfoEditMode(!contactInfoEditMode);
                  }
                }}
            type="submit"
                className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (contactInfoEditMode) {
                    handleEditClick();
                  } else {
                    setContactInfoEditMode(!contactInfoEditMode);
                  }
                }}
                type="submit"
               
                className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                {contactInfoEditMode ? "Save" : "Edit"}
              </button>
            </> */}
          </div>
        </div>
      ))}
         </div>

        
        </div>
      )}
    </div>
  );
};

export default ViewBusiness;
