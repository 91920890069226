import React, { useState , useEffect} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import config  from "../../config/config"



const OwnerPasswordChangeModal = ({ isOpen, onClose }) => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);


  const [passwordMatch , setPasswordMatch] = useState()
  const [ownerId , setOwnerId] = useState()

    const navigate = useNavigate()
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  console.log("user role is" , user_role);
  console.log("bus id is " , business_id);
  console.log("user id is " , user_id);


  const CurrentPasswordTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-92px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const NewPasswordTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-92px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const ConfirmPasswordTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-92px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const ConfirmPasswordTooltip2 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-92px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  useEffect(() => {
    axios
      .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log(response);
        const businessData = response.data.business_data[0];
        console.log(businessData);
    
        setOwnerId(response.data.business_data[0].owner_id)  
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);


    const handleOwnerPasswordChange = async()=>{

        if(newPassword !== confirmNewPassword){
            setPasswordMatch("Password and confirm password does not match")
       
            return
        }

        try{
            const response = await axios.put(`${baseURL}${btoa("login/updateownerpassword")}`, {
                edit_ownerId: user_id,
                email : localStorage.getItem("userEmail"),
                edit_OldPassword: currentPassword,
                edit_NewPassword: newPassword
            })
            console.log(response);
            navigate("/")
             toast.success("Password changed, please login with the new password", {
                duration: 2000,
                autoClose : 2000

                });

                // Delay the page reload for 2 seconds
                setTimeout(() => {
                    localStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                }, 2000);
            
            
        }catch(error){
            console.log("error changing password" , error);
        }
    }

    const isNumeric = (value) => {
      return /^\d+$/.test(value);
    };
    
      const isSixDigits = (value) => {
      return /^\d{0,6}$/.test(value);
    };

    return (
    
      <div className={`fixed inset-0 bg-gray-300 bg-opacity-50 z-50`}>
       <div className="flex items-center justify-center min-h-screen">
         <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
 
           <label className="block mb-2 text-gray-900" htmlFor="currentPassword">
             Current Password:
           </label>
           <input
              type={isPasswordVisible ? "text" : "password"}
              pattern="\d{0,6}"
              inputMode="numeric"
             value={currentPassword}
             onChange={(e) => {
               const inputValue = e.target.value;
               if (isNumeric(inputValue) && isSixDigits(inputValue)) {
                 setCurrentPassword(inputValue);
               } else if (inputValue === '') {
                 setCurrentPassword('');
               }
             }}
             className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
             required
           />
            {currentPassword.length > 0  &&(
                             <div
                               className="absolute cursor-pointer"
                               style={{
                                 marginLeft : "355px",
                                 marginTop : "-45px"
                               }}
                               onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                             >
                               {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                             </div>
             )}
 
           {currentPassword.length > 0 && currentPassword.length < 6 && (
           <CurrentPasswordTooltip
           content="Password must be 6 digits"
             show={true}
             marginLeft={230}
           />
         )}
         {currentPassword.length > 5 && /^[0]{6}$/.test(currentPassword) && (
          <CurrentPasswordTooltip
            content="Invalid password format"
            show={true}
            marginLeft={230}
          />
        )}
 
           <label className="block mb-2 text-gray-900" htmlFor="newPassword">
             New Password:
           </label>
           <input
              type={isNewPasswordVisible ? "text" : "password"}
             pattern="\d{0,6}"
             inputMode="numeric"
             id="newPassword"
             value={newPassword}
             onChange={(e) => {
               const inputValue = e.target.value;
               if (isNumeric(inputValue) && isSixDigits(inputValue)) {
                 setNewPassword(inputValue);
               } else if (inputValue === '') {
                 setNewPassword('');
               }
             }}
             className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
             required
           />
             {newPassword.length > 0 &&(
                             <div
                               className="absolute cursor-pointer"
                               style={{
                                 marginLeft : "355px",
                                 marginTop : "-45px"
                               }}
                               onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
                             >
                               {isNewPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                             </div>
             )}
 
           {newPassword.length > 0 && newPassword.length < 6 && (
             <NewPasswordTooltip
               content="Password must be 6 digits"
               show={true}
               marginLeft={230}
             />
           )}
            {newPassword.length > 5 && /^[0]{6}$/.test(newPassword) && (
            <NewPasswordTooltip
              content="Invalid password format"
              show={true}
              marginLeft={230}
            />
          )}
 
           <label className="block mb-2space-x-4 text-gray-900" htmlFor="confirmNewPassword">
             Confirm New Password:
           </label>
           <input
               type={isConfirmPasswordVisible ? "text" : "password"}
              pattern="\d{0,6}"
              inputMode="numeric"
              id="confirmNewPassword"
             value={confirmNewPassword}
             onChange={(e) => {
              setPasswordMatch("")
               const inputValue = e.target.value;
               if (isNumeric(inputValue) && isSixDigits(inputValue)) {
                 setConfirmNewPassword(inputValue);
               } else if (inputValue === '') {
                 setConfirmNewPassword('');
               }
             }}
             className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
             required
           />
           {confirmNewPassword.length > 0  &&(
                             <div
                               className="absolute cursor-pointer"
                               style={{
                                 marginLeft : "355px",
                                 marginTop : "-45px"
                               }}
                               onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                             >
                               {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                             </div>
             )}
 
           {confirmNewPassword.length > 0 && confirmNewPassword.length < 6 && (
             <ConfirmPasswordTooltip
               content="Password must be 6 digits"
               show={true}
               marginLeft={230}
             />
           )}
            {confirmNewPassword.length > 5 && /^[0]{6}$/.test(confirmNewPassword) && (
            <ConfirmPasswordTooltip
              content="Invalid password format"
              show={true}
              marginLeft={230}
            />
          )}
           {passwordMatch === "Password and confirm password does not match" ?
             <ConfirmPasswordTooltip2
               content="password does not match"
               show={true}
               marginLeft={230}
             /> : ""
           }
           
 
           <div className="flex justify-end space-x-3">
             <button
               onClick={onClose}
               className="hover:bg-red-500 border-[#3479E0] border-2 text-gray-900 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
             >
               Cancel
             </button>
             <button
              onClick={handleOwnerPasswordChange}
              className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-gray-900 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              disabled={/^[0]{6}$/.test(currentPassword) || /^[0]{6}$/.test(newPassword) || /^[0]{6}$/.test(confirmNewPassword)}
             >
               Save
             </button>
           </div>
         </div>
       </div>
     </div>
   );
};

export default OwnerPasswordChangeModal;
