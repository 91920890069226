import React, { useEffect, useState, useRef } from "react";
import Revenue from "./Revenue";
import ScrollToTop from "./ScrollTop";
import Table from "./Table";
import { createColumnHelper ,
  useReactTable, 
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel, } from "@tanstack/react-table";
  import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
  import { AiOutlineSearch } from "react-icons/ai";
import TableMenu from "./TableMenu";
import PaymentsCount from "./PaymentsCount";
import PaymentsMenu from "./PaymentsMenu";
import ProductCount from "./ProductsCount";
import ProductMenu from "./ProductMenu";
import axios from "axios";
import Search from "../Search/Search";
import { FaTimesCircle } from "react-icons/fa";
import 'react-tippy/dist/tippy.css';
import { Tooltip } from "react-tippy";
import { Link, useNavigate } from "react-router-dom";
import { deleteProduct } from "../../actions/customerActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PopUpModal from "./DeleteModal";
import ProductDashboardTable from "./ProductDashboardTable";
import config  from "../../config/config"
import ReactDOM from "react-dom"
import { addProduct, deleteAllProduct } from "../../store/ProductSlice";
import PopUpModalForDeleteProduct from "./ProductDeleteModel";
import { showToastForUpdateProduct } from "../toasts/toastsForProduct";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import ScrollingMessage from "./ScrollingMessage";

const columnHelper = createColumnHelper();

function Product() {
  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [originalItemData, setOriginalItemData] = useState([]);
  const [description , setDescription] = useState('');
  const [productId , setProductId] = useState('');
  const [productName , setProductName] = useState('')
  const [searchValue, setSearchValue] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [sorting , setSorting] = React.useState([])
  const [filtering , setFiltering] = useState("")
  const [columnFilters, setColumnFilters] = useState("")
  const [triggerFetch, setTriggerFetch] = useState(false);
  // const [productDescription, setProductDescription] = useState({
  //   description: '',
  //   productId: ''
  // });

  const allProducts = useSelector((state => state.product.allProducts))
  // console.log("all products from store" , allProducts);

  const [productData, setProductData] = useState([]);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const businessId = localStorage.getItem("business_id")



  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  

  const handleDeleteModalItem = (productId) => {
    if (table.getSelectedRowModel().flatRows[0]?.original) {
      setOpenDeleteModalId(productId);
    }
  };


  
  const handleRowSelect = (row) => {
    setDescription(row.original.product_des);
    setProductId(row.original.product_id);
    setProductName(row.original.product_name);
  };

  const handleDescriptionSubmit = async  () => {
    // console.log("description", document.getElementById("tableInput").value);
    // console.log("product id", productId);
    // console.log("product name", productName);
    try {
      const response = await axios.put(
        `${baseURL}${btoa("retailproduct/update")}`,
        {
          managerId : localStorage.getItem("user_id"),
          businessId : businessId,
          edit_Id : productId,
          edit_productDes: document.getElementById("tableInput").value.replace(/[^A-Za-z0-9\s]/g, ""),
          edit_productName: productName,
        }
      );
      showToastForUpdateProduct(`${productName} data updated successfully`)
      // window.location.reload()
      table.resetRowSelection()
      dispatch(deleteAllProduct())
      setTriggerFetch(prev => !prev);
      // console.log("response" , response , "id" , productId , "description" , document.getElementById("tableInput").value ) ;
    } catch (error) {
      console.error(error , "error change in description");
    }
  };

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      return Object.values(rowSelection).some((selected) => selected);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}/${btoa("retailproduct/view")}/${btoa(businessId)}`)
      .then((response) => {
        const sortedProductData = response.data.products_data.sort(
          (a, b) => b.product_id - a.product_id
        );
        dispatch(deleteAllProduct())
        dispatch(addProduct(sortedProductData))

        // setProductData(sortedProductData);
        // setOriginalItemData(sortedProductData);

        // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, [triggerFetch]);


  const columns = [
  {
    id: "checkbox",
    accessor: "",
    cell: ({ row }) => (
      <IndeterminateCheckbox
        {...{
          checked: row.getIsSelected(),
          disabled: isRowCheckboxDisabled(row),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
          className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
        }}
      />
    ),
    enableColumnFilter: false, // Disable filter for checkbox column
    enableSorting: false, // Disable sorting for checkbox column
  },
  columnHelper.accessor("added_on", {
    header: "Added On",
    cell: (props) => {
      const date = new Date(props.row.original.added_on);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
      return (
        <div>{formattedDate}</div>
      );
    },
    enableColumnFilter: false,
    enableSorting: true,
  }),
  columnHelper.accessor("product_name", {
    header: "Product",
  }),
  columnHelper.accessor(" ", {
    header: "Product Description",
    cell: ({ row }) => {
      if (row.getIsSelected()) {
        handleRowSelect(row); // Call handleRowSelect when a row is selected
        return (
          <div className="space-x-[-70px]">
            <input
              id="tableInput"
              onChange={(e) => {
                console.log('Input value:', e.target.value);
                // setDescription(e.target.value);
              }}
              style={{
                border: '1px solid #ccc',
                padding: '5px 10px',
                borderRadius: '5px',
                textAlign: "center",
                width : "95%"
              }}
              defaultValue={description}
              disabled={isRowCheckboxDisabled(row)}
              {...{
                defaultChecked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
              }}
            />
            <button
              class="descriptionButton"
              onClick={handleDescriptionSubmit} // Consider passing row.original.product_id here
              className="px-4 py-1 bg-transparent border-2 border-green-500 text-green-500 rounded-sm hover:bg-green-500 hover:text-white transition duration-300 ease-in-out"
            >
              Save
            </button>
          </div>
        );
      } else {
        return row.original.product_des;
      }
    },
  })
];

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };
  
  const table = useReactTable({
    data: allProducts || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });
  const currentPage = table.options.state.pagination.pageIndex;

  return (
    <div className=" flex justify-center items-center flex-col w-full pb-5">
       <ScrollingMessage/>
      <div className="flex justify-between items-center w-full px-8 mt-6 mb-8 pl-9">
        <h1 className="font-bold text-lg text-gray-400">Products Dashboard - {allProducts.length} Products </h1>
        
        <div>
          <button
             className="flex items-center mt-2 justify-center font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
            type="button"
            onClick={() => navigate(`/products/productform`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Product
          </button>
        </div>

      </div>
      {/* <ProductCount /> */}
      <div className="flex w-full mt-10 pl-9 pr-7 space-x-4">
        <div className="relative w-full">
              <AiOutlineSearch
                size={18}
                className="absolute mt-2 left-3 text-gray-700"
              />
              <input
                type="text"
                placeholder="Search..."
                className="pl-10 px-6 py-1 border-2 rounded-lg border-gray-300 outline-none block w-full focus:ring-3"
                value={filtering}
                onChange={e => setFiltering(e.target.value)}
              />
            </div>

          <div className="flex items-center space-x-3 relative">
            {table.getSelectedRowModel().flatRows[0]?.original && (
                      <Tooltip
                        title="Delete"
                        position="top"
                        trigger="mouseenter"
                      >
                        <button
                          className="items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-red-700 rounded-lg"
                          type="button"
                          onClick={()=> {
                            const productId = table.getSelectedRowModel().flatRows[0]?.original.product_id;
                            // console.log("product det" , productId);
                            handleDeleteModalItem(productId)
                          }}
                        >
                          <div className="rounded-full p-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </button>
                      </Tooltip>
                    )}
          </div>
      </div>

      <div className="w-full rounded-xl pl-9 pr-10 mt-10 bg-white">
       
          <table className="w-full table-auto bg-transparent rounded-xl mt-4">
              <thead className="bg-gray-500/20 text-gray-500">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="text-center text-xs">
                    {headerGroup.headers.map((header, index) => {
                      const isFirstHeader = index === 0;
                      const isLastHeader = index === headerGroup.headers.length - 1;
                      const headerClasses = [
                        'p-3 font-bold uppercase',
                        isFirstHeader ? 'rounded-l-xl' : '',
                        isLastHeader ? 'rounded-r-xl' : '',
                      ];

                      const isSorted = header.column.getIsSorted();
                      const showSortingIcons = header.column.columnDef.enableSorting !== false;
                      const ArrowUpIcon = (
                        <FaArrowUpLong
                          className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );
                      const ArrowDownIcon = (
                        <FaArrowDownLong
                          className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );

                      return (
                        <th
                          key={header.id}
                          className={headerClasses.join(' ')}
                          colSpan={header.colSpan}
                          onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                        >
                          <div className="flex items-center justify-center">
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            {showSortingIcons && (
                              <div className="flex items-center ml-2">
                                {ArrowDownIcon}
                                {ArrowUpIcon}
                              </div>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
          </table>

                <div className="flex items-center mr-7 mt-6 gap-2 justify-end">
                  <button
                    className={`px-4 py-2 rounded-l-lg focus:outline-none flex items-center ${
                      table.getCanPreviousPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : " cursor-not-allowed"
                    }`}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <MdNavigateBefore className="w-6 h-6" />
                    Prev
                  </button>
                  {[...Array(table.getPageCount()).keys()].map((page) => (
                    <button
                      key={page}
                      onClick={() => table.setPageIndex(Number(page))}
                      className={`px-4 py-2  focus:outline-none ${
                        currentPage === page
                          ? " rounded-full bg-[#3479E0] text-white "
                          : " rounded-full bg-white dark:text-black hover:bg-[#3479E0]/50 hover:text-white"
                      }`}
                    >
                      {page + 1}
                    </button>
                  ))}
                  <button
                    className={`px-4 py-2 rounded-r-lg  focus:outline-none flex items-center justify-center${
                      table.getCanNextPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : "bg-gray-300 cursor-not-allowed"
                    }`}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next
                    <MdNavigateNext className="w-6 h-6" />
                  </button>
                </div>

      </div>
      {openDeleteModalId && (
        <PopUpModalForDeleteProduct
          onClose={() => setOpenDeleteModalId(null)}
          productId={table.getSelectedRowModel().flatRows[0]?.original.product_id}
          productName={table.getSelectedRowModel().flatRows[0]?.original.product_name}
          resetRowSelection={table.resetRowSelection}
        />
      )}
      <ScrollToTop />
    </div>
  );
}

export default Product;
