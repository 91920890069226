// AddItemForm.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; // Import useNavigate
import { addItem } from "./../../actions/customerActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTabs } from "../pages/TabContext";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import "react-tippy/dist/tippy.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import CustomTooltip from "./CustomeTooltip";
import config  from "../../config/config"
import ReactDOM from "react-dom"
import { showToastForAddItem } from "../toasts/toastForItem";

function ItemForm() {
  const handleCancel = () => {
    navigate(`/itemsdashboard`);
  };
  const [itemNameError, setItemNameError] = useState("");
  const [desNameError, setDesNameError] = useState("");
  const navigate = useNavigate(); // Initialize the navigate function
  const [productOptions, setProductOptions] = useState([]);
  const [invalidItemName , setInvalidItemName] = useState("")
  const [invalidItemPrice , setInvalidItemPrice] = useState("")
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  const businessId = localStorage.getItem("business_id")
  useEffect(() => {
    async function fetchProductOptions() {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("retailproduct/view")}/${btoa(businessId)}`
        );
        console.log("response" , response);
        // Assuming the response.data is an array of products
        setProductOptions(response.data.products_data);
       
      } catch (error) {
        console.error("Error fetching product options:", error);
      }
    }

    fetchProductOptions();
  }, []);
  console.log(productOptions);
  const [formData, setFormData] = useState({
    itemName: "",
    itemUnitPrice : "",
    itemDes: "",
    productId: "",
    businessId : localStorage.getItem("business_id"),
    managerId : localStorage.getItem("user_id")
  });

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: capitalizeWords(value),
    });
  };
  const showToast = (message, type, itemName) => {
    const toastColorClass = type === "success" ? "bg-green-50 text-green-500" : "bg-red-50 text-red-500";
    const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#2BDE3F]" size={24} /> : <FaTimesCircle className="mr-2 text-red-500" size={24} />;
  
    const iconContainer = document.createElement('div');
    ReactDOM.render(iconComponent, iconContainer);
    
    const toastContainer = document.createElement('div');
    toastContainer.className = `toast-container flex justify-between items-center ${toastColorClass}`;
    
    toastContainer.innerHTML = `
      <div className="rounded-lg" style="white-space: nowrap; width: 600px;"> 
        ${message}
      </div>
      <div className="rounded-lg">${iconContainer.innerHTML}</div>
    `;
    
    toastContainer.style.position = 'fixed';
    toastContainer.style.top = '320px'; 
    toastContainer.style.left = '790px';
    toastContainer.style.transform = 'translate(-50%, -50%)'; // Center the container horizontally and vertically
    toastContainer.style.padding = '10px';
    toastContainer.style.borderLeft = type === "success" ? "5px solid #2BDE3F" : "5px solid red";
    toastContainer.style.background = type === "success" ? "#f3faf7" : "#fdf2f2";
    toastContainer.style.minHeight = "10px";
    toastContainer.style.display = "flex";
    toastContainer.style.justifyContent = "space-between";
    toastContainer.style.width = "auto"; // Adjust width as needed
  
    document.body.appendChild(toastContainer);
  
    const initialTop = parseFloat(getComputedStyle(toastContainer).top);
  
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const distance = initialTop - scrollTop;
      toastContainer.style.top = `${distance}px`; // Adjust position based on scroll
    };
  
    window.addEventListener('scroll', handleScroll);
  
    setTimeout(() => {
      document.body.removeChild(toastContainer);
      window.removeEventListener('scroll', handleScroll);
      window.scrollTo(0, 0); // Move screen back to top after dismissing toast
    }, 3500);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);

    
    if(formData.itemName.replace(/\s/g,"").length < 3){
      return;
    }
    if(formData.itemDes.replace(/\s/g,"").length > 0 && formData.itemDes.replace(/\s/g,"").length < 3){
      return;
    }


    try {
      const response = await axios.post(`${baseURL}${btoa("items/create")}`, formData);
      // console.log("item data" , formData);
      if (response.status === 200) {
        const responseData = await response.data;
        if (responseData.dataSavingStatus === true) {
          showToastForAddItem(`${formData.itemName} added successfully` , "success" , "")
        }
      } else {
        console.log("Request failed with status: " + response.status);
        response.data.then((data) => {
          console.log(data);
        });
        showToast("Unable to add new item,please try again", "error");
      }
    } catch (error) {
      console.error(error);
      showToast("Unable to add new item, please try again", "error");
    }
    navigate(`/itemsdashboard`);
  };
  // console.log("Form Data:", formData);

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z0-9\s]/g, "");
    let inputValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
    const containsValidCharacters = /^[a-zA-Z0-9 ]*$/.test(inputValue);
    if (containsValidCharacters) {
      setInvalidItemName("");
    } else {
      setInvalidItemName("Invalid characters");
    }
    if (inputValue.trim() === "") {
      setInvalidItemName("");
    }
    setFormData({
      ...formData,
      [name]: inputValue
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setItemNameError("Item Name must be at least 3 characters");
    } else {
      setItemNameError("");
    }
  };
  
  const handleUnitPriceChange = (e) => {
    const { name, value } = e.target;
    const containsNonZero = /[1-9]/.test(value);
  
    if (containsNonZero) {
      setInvalidItemPrice("valid");
    } else {
      setInvalidItemPrice("Invalid number");
    }
    if (value === "") {
      setInvalidItemPrice("");
    }
    if (!isNaN(value) || value === "") {
      if (value <= 10000) {
        setFormData({
          ...formData,
          [name]: value
        });
      } else {
        setInvalidItemPrice("Invalid number");
      }
    }
  };
  
  const handleUnitPriceBlur = (e) => {
    const { name, value } = e.target;
  
    if (value && !isNaN(value)) {
      const formattedValue = parseFloat(value).toFixed(5); // Format to 5 decimal places
      setFormData({
        ...formData,
        [name]: formattedValue
      });
    }
  };

  const handleDesChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z0-9\s]/g, "");
    let inputValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1); // Remove non-alphabetic characters except spaces
    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setDesNameError("Description Name must be at least 3 letters");
    } else {
      setDesNameError("");
    }
  };
  const areRequiredFieldsFilled = formData.productId && formData.itemName;
  return (
    <div className="pt-16 h-screen">
      <h1 className="mx-8 font-bold mb-24 text-gray-400 text-lg mt-[-20px]">
        Items {">>"} New Item
      </h1>

      <form onSubmit={handleSubmit}>
        <div className="w-auto  bg-white rounded-box mx-8  rounded-xl">
          <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
            New Item Info
          </h2>
          <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
            <div className="flex sm:col-span-3 gap-3">
              <div class="w-full">
                <label
                  htmlFor="productId"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Product Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <select
                  id="productId"
                  name="productId"
                  value={formData.productId}
                  onChange={handleChange}
                  required
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                >
                  <option value="">Select Product</option>
                  {productOptions.map((product) => (
                    <option key={product.product_id} value={product.product_id}>
                      {product.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="w-full">
                <label
                  htmlFor="itemName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Item Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1 content={itemNameError} show={!!itemNameError} marginLeft={190}/>

                  {invalidItemName === "Invalid number" && !itemNameError &&  (
                      <CustomTooltip1
                        content={"Invalid Item Name"}
                        show={"Invalid Item Name"}
                        marginLeft={163}
                      />
                  )} 

                <input
                  type="text"
                  id="itemName"
                  name="itemName"
                  value={formData.itemName}
                  onChange={handleItemChange}
                  required
                  class={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    itemNameError ? "border-red-500" : ""
                  }`}
                  placeholder="Enter Item Name"
                  data-tip={itemNameError} 
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="itemUnitPrice"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Unit Price{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                {/* <CustomTooltip1 content={itemNameError} show={!!itemNameError} marginLeft={308}/> */}
                {invalidItemPrice === "Invalid number" && !itemNameError &&  (
                      <CustomTooltip1
                        content={"Invalid Price"}
                        show={"Invalid Price"}
                        marginLeft={163}
                      />
                  )} 
                   {invalidItemPrice === "Invalid number" && !itemNameError &&  (
                      <CustomTooltip1
                        content={"Invalid Price"}
                        show={"Invalid Price"}
                        marginLeft={163}
                      />
                  )} 
                  
                <input
                  type="text"
                  id="itemUnitPrice"
                  name="itemUnitPrice"
                  value={formData.itemUnitPrice}
                  onChange={handleUnitPriceChange}
                  onBlur={handleUnitPriceBlur}
                  required
                  class={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    itemNameError ? "border-red-500" : ""
                  }`}
                  placeholder="Enter Unit Price"
                  // data-tip={itemNameError} 
                />
              </div>

            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="itemDes"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Item Description
              </label>
              <CustomTooltip1 content={desNameError} show={!!desNameError} marginLeft={800}/>

              <input
                type="text"
                id="itemDes"
                name="itemDes"
                value={formData.itemDes}
                onChange={handleDesChange}
                class={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  desNameError ? "border-red-500" : ""
                }`}
                placeholder="Enter Item Description"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            {areRequiredFieldsFilled && (
              <>
                <button
                  onClick={handleCancel}
                  type="submit"
                  class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  disabled={formData.itemUnitPrice === "" || invalidItemName === "Invalid number"  || invalidItemPrice === "Invalid number"}
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default ItemForm;
