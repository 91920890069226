import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import axios from "axios";
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline,
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import CustomTooltip from "../CustomeTooltip";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import config  from "../../../config/config"
import ReactDOM from 'react-dom';
import { showToastForAddCustomer } from "../../toasts/toastForCustomer";


function CustomerData() {
  const navigate = useNavigate();
  const [isCardSelected, setIsCardSelected] = useState(false);
  const [emailAlreadyExist , setEmailAlreadyExist] = useState("");
  const [contactNoAlreadyExist , setContactNoAlreadyExist] = useState("");
  const [isSameAsAboveSelected, setIsSameAsAboveSelected] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [invalidPhoneNumber , setInvalidPhoneNumber] = useState("");
  const [invalidShippingPhoneNumber , setInvalidShippingPhoneNumber] = useState("");
  const [invalidBusinessAccountNumber , setInvalidBusinessAccountNumber] = useState("");
  const [invalidBusinessZipCode , setInvalidBusinessZipCode] = useState("")
  const [invalidBillingZipCode , setInvalidBillingZipCode] = useState("")
  const [invalidShippingZipCode , setInvalidShippingZipCode] = useState("")
  const [invalidCardZipCode , setInvalidCardZipCode] = useState("")
  const [invalidBankRoutingNumber , setInvalidBankRoutingNumber] = useState("")
  const [invalidBankAccountNumber , setInvalidBankAccountNumber] = useState("")
  const [invalidCardNumber , setInvalidCardNumber] = useState("")
  const [invalidCardCvv , setInvalidCardCvv] = useState("")

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;


  const [shippingInfo, setShippingInfo] = useState({
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhoneNumber: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingZipCode: '',
    shippingCounty : '',
  });

  const handleAddressToggle = () => {
    setIsSameAsAboveSelected((prev) => !prev);

    // If "Same as above" is selected, copy values from the above section
    if (!isSameAsAboveSelected) {
      setShippingInfo({
        contactPersonName: formik.values.contactPersonName,
        contactPersonEmail: formik.values.contactPersonEmail ,
        contactPersonPhoneNumber: formik.values.contactPersonPhoneNumber,
        billingAddress: formik.values.billingAddress,
        billingCity: formik.values.billingCity,
        billingState: formik.values.billingState,
        billingZipCode: formik.values.billingZipCode,
        shippingCounty: formik.values.shippingCounty,
      });
    } else {
      // Reset shippingInfo if "Same as above" is unselected
      setShippingInfo({
        contactPersonName: '',
        contactPersonEmail: '',
        contactPersonPhoneNumber: '',
        billingAddress: '',
        billingCity: '',
        billingState: '',
        billingZipCode: '',
        shippingCounty: '',
      });
    }
  };


  const handleToggle = () => {
    setIsCardSelected(!isCardSelected);
  };
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const EmailExistTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
  const ContactNoExistTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const handleCardCVVChange = (e) => {
    const maxLength = 4;
    const inputValue = e.target.value.replace(/\D/g, "");

    const containsNonZero = /[1-9]/.test(inputValue);
    if(containsNonZero){
      setInvalidCardCvv("valid")
    }else{
      setInvalidCardCvv("Invalid number")
    }
    if(inputValue === ""){
      setInvalidCardCvv("")
    }


    if (inputValue.length <= maxLength) {
      formik.handleChange({
        target: {
          name: "cardCVV",
          value: inputValue,
        },
      });
    }
  };
  const handleBankRoutingChange = (e) => {
    const maxLength = 9;
    const inputValue = e.target.value.replace(/\D/g, "");

    const containsNonZero = /[1-9]/.test(inputValue);
    
    if(containsNonZero){
      setInvalidBankRoutingNumber("valid")
    }else{
      setInvalidBankRoutingNumber("Invalid number")
    }
    if(inputValue === ""){
      setInvalidBankRoutingNumber("")
    }

    if (inputValue.length <= maxLength) {
      formik.handleChange({
        target: {
          name: "bankRouting",
          value: inputValue,
        },
      });
    }
  };
  const handleCardNumberChange = (e) => {
    const maxLength = 19;
    const inputValue = e.target.value.replace(/\D/g, "");

    const containsNonZero = /[1-9]/.test(inputValue);
    
    if(containsNonZero){
      setInvalidCardNumber("valid")
    }else{
      setInvalidCardNumber("Invalid number")
    }

    if(inputValue === ""){
      setInvalidCardNumber("")
    }


    if (inputValue.length <= maxLength) {
      formik.handleChange({
        target: {
          name: "cardNumber",
          value: inputValue,
        },
      });
    }
  };

  // hanlde ZIp code

  const formatZipCode = (value) => {
    const maxLength = 5;
    const inputValue = value.replace(/\D/g, "");

    // Limit the length to 17 digits
    const processedValue = inputValue.slice(0, maxLength);

    console.log("Processed Input Value:", processedValue);

    return processedValue;
  };
  const handleZipCodeChange = (fieldName, e) => {
    const formattedValue = formatZipCode(e.target.value);
    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };

  const formatAccountNumber = (value) => {
    const maxLength = 17;

    // Remove any non-digit characters
    const inputValue = value.replace(/\D/g, "");

    // Limit the length to 17 digits
    const processedValue = inputValue.slice(0, maxLength);

    console.log("Processed Input Value:", processedValue);

    return processedValue;
  };

  const handleAccountNumberChange = (fieldName, e) => {
    const formattedValue = formatAccountNumber(e.target.value);

    const containsNonZero = /[1-9]/.test(formattedValue);
    
    if(containsNonZero){
      setInvalidBankAccountNumber("valid")
    }else{
      setInvalidBankAccountNumber("Invalid number")
    }

    if(formattedValue === ""){
      setInvalidBankAccountNumber("")
    }

    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };

  const businessAccountNumber = (value) => {
    const inputValue = value.replace(/[^a-zA-Z0-9]/g, "");
    const maxLength = 20;
    const truncatedValue = inputValue.slice(0, maxLength);
    const processedValue = truncatedValue.charAt(0).toUpperCase() + truncatedValue.slice(1);
    return processedValue;
  };
  
  const handlebusinessAccountNumberChange = (fieldName, e) => {
    const formattedValue = businessAccountNumber(e.target.value);
    const containsNonZero = /[1-9]/.test(formattedValue);
    
    if (containsNonZero) {
      setInvalidBusinessAccountNumber("valid");
    } else {
      setInvalidBusinessAccountNumber("Invalid number");
    }
    
    if (formattedValue === "") {
      setInvalidBusinessAccountNumber("");
    }
  
    console.log(invalidBusinessAccountNumber === "");
  
    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };
  


  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    cleaned = cleaned.slice(0, 10);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return cleaned; // Return cleaned input if no match
  }




  const handlePhoneNumberChange = (fieldName, e) => {
    
    setContactNoAlreadyExist("")
    const phoneNumber = e.target.value.replace(/[-()]/g, '');
    const formattedValue = formatPhoneNumber(phoneNumber);
    const containsNonZero = /[1-9]/.test(formattedValue);
    
    if(containsNonZero){
      setInvalidPhoneNumber("valid")
    }else{
      setInvalidPhoneNumber("Invalid number")
    }

    if(formattedValue === ""){
      setInvalidPhoneNumber("")
    }

      formik.handleChange({
        target: {
          name: fieldName,
          value: formattedValue,
        },
      });
  };

  useEffect(() => {
    console.log(invalidPhoneNumber);
  }, [invalidPhoneNumber]);
 
    

  const validationSchema = Yup.object().shape({
    businessAccount: Yup.string()
      .min(3, "Account No must be of 3 to 20 char's"),
      // .required("Account No is required"),

    businessName: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Name should be at least 3 char's"),
      // .required("Business Name is required"),
    businessAddress: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
      // .required("Business Address is required"),
    businessCity: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "City should be at least 3 char's"),
      // .required("Business City is required"),
    businessState: Yup.string(),
    businessZipCode: Yup.string()
      .matches(/^[0-9]{5}$/, "Zip Code must be of 5 digits"),
      // .required("Zip Code is required"),
    contactPersonName: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Name should be at least 3 characters"),
    contactPersonEmail: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Invalid email address"
      ),
    // contactPersonPhoneNumber: Yup.string().matches(
    //   /^\(\d{3}\)\d{3}-\d{4}$/  || invalidPhoneNumber === "Invalid number",
    //   "Invalid phone number format"
    // ),
    
    billingAddress: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
    billingCity: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "City should be at least 3 char's"),
    billingState: Yup.string(),
    billingZipCode: Yup.string().matches(
      /^[0-9]{5}$/,
      "Zip Code must be of 5 digits"
    ),
    shippingTo: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
    shippingAddress: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Address should be at least 3 char's"),
    shippingCity: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "City should be at least 3 char's"),
    shippingState: Yup.string(),
    shippingZipCode: Yup.string().matches(
      /^[0-9]{5}$/,
      "Zip Code must be of 5 digits"
    ),
    // shippingContactNumber: Yup.string().matches(
    //   /^\(\d{3}\)\d{3}-\d{4}$/,
    //   "Invalid phone number format"
    // ),
    shippingDeliveryInstructions: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Instructions should be at least 3 char's"),
    bankCurrency: Yup.string(),
    bankRouting: Yup.string().matches(
      /^[0-9]{9}$/,
      "Routing No must be of 9 digits"
    ),
    bankAccount: Yup.string().matches(
      /^[0-9]{17}$/,
      "Account No must be exactly 17 digits"
    ),
    cardNumber: Yup.string().matches(
      /^[0-9]{10,19}$/,
      "Card number must be 10-19 digits"
    ),
    // cardExpiryDate: Yup.string().matches(
    //   /^(0[1-9]|1[0-2])\/[0-9]{2}$/,
    //   "Invalid date format (MM/YY)"
    // ),
    cardCVV: Yup.string().matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits"),
    nameOnCard: Yup.string()
      .transform((originalValue) => {
        return originalValue
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .min(3, "Name on  should be at least 3 characters"),
    creditCardName: Yup.string(),
    cardPaymentCurrency: Yup.string(),
    cardZipCode: Yup.string().matches(
      /^[0-9]{5}$/,
      "Zip Code must be of 5 digits"
    ),
    paymentType: "",
  });

  const formatDate = (date) => {
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${month}/${year}`;
  };
  

  useEffect(() => {
    // Update the Formik values with the latest shippingInfo data
    formik.setValues((prevValues) => ({
      ...prevValues,
      shippingTo: shippingInfo.contactPersonName,
      shippingAddress: shippingInfo.billingAddress,
      shippingCity: shippingInfo.billingCity,
      shippingState: shippingInfo.billingState,
      shippingZipCode: shippingInfo.billingZipCode,
      shippingCounty: shippingInfo.shippingCounty,
      shippingContactNumber: shippingInfo.contactPersonPhoneNumber,
     
      // Add other fields as needed
    }));
  }, [shippingInfo]);

  const businessId = localStorage.getItem("business_id")
  const managerId = localStorage.getItem("user_id")

  const formik = useFormik({
    initialValues: {
      businessId : businessId,
      managerId : managerId,
      businessAccount: "",
      businessName: "",
      businessAddress: "",
      businessCity: "",
      businessState: "",
      businessZipCode: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhoneNumber: "",
      billingAddress: "",
      billingCity: "",
      billingState: "",
      billingZipCode: "",
      shippingTo: shippingInfo.contactPersonName,
      shippingAddress: shippingInfo.billingAddress,
      shippingCity: shippingInfo.billingCity,
      shippingState: shippingInfo.billingState,
      shippingZipCode: shippingInfo.billingZipCode,
      shippingCounty: shippingInfo.shippingCounty,
      shippingContactNumber: shippingInfo.contactPersonPhoneNumber,
      shippingDeliveryInstructions: "",
      bankCurrency: "",
      bankRouting: "",
      bankAccount: "",
      cardNumber: "",
      cardExpiryDate: "",
      cardCVV: "",
      nameOnCard: "",
      cardZipCode: "",
      paymentType: "",
      creditCardName: "",
      cardPaymentCurrency: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if(formik.values.cardExpiryDate === "00/00") return;
      
      try {
        // console.log("Form Values:", values);
        const encodedSegment = btoa('customer/create');
        const encodedURL = `${baseURL}${encodedSegment}/`;

        const response = await axios.post(encodedURL,values);
        console.log("API Response:", response);
        if (response.status === 200) {
          const responseData = await response.data;
          // console.log("Response Data:", responseData);

          if (responseData.dataSavingStatus === true) {
            // Array to store the names of filled frames
            const filledFrames = [];

            // Check each frame and add its name to the array
            if (values.businessName) filledFrames.push("Info");
            if (values.contactPersonName) filledFrames.push("Contact Info");
            if (values.billingAddress) filledFrames.push("Billing Info");
            if (values.shippingTo) filledFrames.push("Shipping Info");
            if (values.bankCurrency) filledFrames.push("Payment Info");

            // Construct success message based on filled frames
            let successMessage = `New ${values.businessName}`;
            if (filledFrames.length > 0) {
              successMessage += ` ${filledFrames.join(
                ", "
              )} was added successfully`;
            }
            showToastForAddCustomer(`${values.businessName} was added successfully`, "success")
            showToast(
              successMessage,
              "success",
              values.businessName,
              filledFrames
            );
            navigate(`/customers`);
          } else {
            showToast("Unable to add new customer, please try again", "error");
          }
        } else {
          console.log("Request failed with status:", response.status);
          showToast("Unable to add new customer, please try again", "error");
        }
      } catch (error) {
        console.error("API Error:", error);
        console.log("Error Details:", error.response);

        if(error.response.data.userStatus === "Email already exist"){
          setEmailAlreadyExist("Email already registered")
        }
        if(error.response.data.userStatus === "Contact number already exist"){
          setContactNoAlreadyExist("Contact number is already registered")
        }
        // showToast("Unable to add new customer, please try again", "error");
      }
    },
  });


  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };
  const expirationDatePattern = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;

  const validateExpirationDate = (date) => {
    return expirationDatePattern.test(date);
  };

  const handleExpirationDateChange = (e) => {
   
    const { name, value } = e.target;
    const isBackspace = e.nativeEvent && e.nativeEvent.inputType === "deleteContentBackward";
    const cleanedValue = value.replace(/\D/g, '');
    const truncatedValue = cleanedValue.slice(0, 4);
    const formattedValue = truncatedValue.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
    

    if (isBackspace && truncatedValue.length === 2) {
      const slicedValue = truncatedValue.slice(0, 1);
      return formik.setFieldValue("cardExpiryDate" , formattedValue);
    }
  
    const isValidExpirationDate = validateExpirationDate(formattedValue);
    formik.setFieldValue("cardExpiryDate" , formattedValue);
  }


  const showToast = (message, type, businessName) => {
    const toastColorClass = type === "success" ? "bg-green-50 text-green-500" : "bg-red-50 text-red-500";
    const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#2BDE3F]" size={24} /> : <FaTimesCircle className="mr-2 text-red-500" size={24} />;
  
    const iconContainer = document.createElement('div');
    ReactDOM.render(iconComponent, iconContainer);
    
    const toastContainer = document.createElement('div');
    toastContainer.className = `toast-container flex justify-between items-center ${toastColorClass}`;
    
    toastContainer.innerHTML = `
      <div className="rounded-lg" style="white-space: nowrap; width: 600px;"> 
        ${message}
      </div>
      <div className="rounded-lg">${iconContainer.innerHTML}</div>
    `;
    
    toastContainer.style.position = 'fixed';
    toastContainer.style.top = '290px'; // Initial position
    toastContainer.style.left = '930px';
    toastContainer.style.transform = 'translate(-50%, -50%)'; // Center the container horizontally and vertically
    toastContainer.style.zIndex = '9999';
    toastContainer.style.padding = '10px';
    toastContainer.style.borderLeft = type === "success" ? "5px solid #2BDE3F" : "5px solid red";
    toastContainer.style.background = type === "success" ? "#f3faf7" : "#fdf2f2";
    toastContainer.style.minHeight = "10px";
    toastContainer.style.display = "flex";
    toastContainer.style.justifyContent = "space-between";
    toastContainer.style.width = "auto"; // Adjust width as needed
  
    document.body.appendChild(toastContainer);
  
    const initialTop = parseFloat(getComputedStyle(toastContainer).top);
  
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const distance = initialTop - scrollTop;
      toastContainer.style.top = `${distance}px`; // Adjust position based on scroll
    };
  
    window.addEventListener('scroll', handleScroll);
  
    setTimeout(() => {
      document.body.removeChild(toastContainer);
      window.removeEventListener('scroll', handleScroll);
      window.scrollTo(0, 0); // Move screen back to top after dismissing toast
    }, 500);
  };
  
  const areRequiredBusinessFilled = () => {
    return (
      formik.values.businessName &&
      formik.values.businessAccount &&
      formik.values.businessAddress &&
      formik.values.businessCity &&
      formik.values.businessState &&
      formik.values.businessZipCode &&
      invalidBusinessAccountNumber !== "" &&
      invalidBusinessZipCode !== ""
    );
  };
  const areRequiredContactFilled = () => {
    return (
      formik.values.contactPersonName &&
      formik.values.contactPersonEmail &&
      formik.values.contactPersonPhoneNumber &&
      invalidPhoneNumber !== "" 

    );
  };
  const areRequiredBillingFilled = () => {
    return (
      formik.values.billingAddress &&
      formik.values.billingCity &&
      formik.values.billingState &&
      formik.values.billingZipCode &&
      invalidBillingZipCode !== ""
    );
  };
  const areRequiredshippingFilled = () => {
    return (
      shippingInfo.contactPersonName &&
      shippingInfo.billingAddress &&
      shippingInfo.billingCity &&
      shippingInfo.billingState &&
      shippingInfo.billingZipCode &&
      shippingInfo.shippingCounty &&
      shippingInfo.contactPersonPhoneNumber &&
      invalidShippingPhoneNumber !== "" &&
      invalidShippingZipCode !== ""
    );
  };
  const areRequiredpaymentFilled = () => {
    return (
      (formik.values.bankAccount &&
        formik.values.bankCurrency &&
        formik.values.bankRouting &&
        invalidBankAccountNumber !== "" &&
        invalidBankRoutingNumber !== ""
        ) ||
      (formik.values.cardNumber &&
        formik.values.cardExpiryDate &&
        formik.values.cardCVV &&
        formik.values.cardZipCode &&
        formik.values.nameOnCard &&
        formik.values.creditCardName &&
        formik.values.cardPaymentCurrency &&
        invalidCardCvv !== "" &&
        invalidCardNumber !== "" &&
        invalidCardZipCode !== ""
        )
    );
  };
  return (
    <div className="pt-20 pb-20">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">
          <div className="flex items-center">
            <MdPersonOutline className="text-gray-700 w-6 h-6 mr-2 mb-10" />
            <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
              New Customer info
            </h2>
          </div>
          <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="businessAccount"
                className="block mb-2 text-base font-medium text-gray-900"
              >
                Account #{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessAccount}
                show={!!formik.errors.businessAccount}
                marginLeft={120}
              />
                {invalidBusinessAccountNumber === "Invalid number" && !formik.errors.businessAccount &&  (
                  <CustomTooltip1
                    content={"Invalid Number"}
                    show={"Invalid Number"}
                    marginLeft={163}
                  />
                )}

              <input
                type="text"
                id="businessAccount"
                name="businessAccount"
                value={formik.values.businessAccount}
                onChange={(e) =>
                  handlebusinessAccountNumberChange("businessAccount", e)
                }
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.businessAccount &&
                  formik.errors.businessAccount
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Account No"
              />
              {/* {formik.touched.businessAccount &&
                formik.errors.businessAccount && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.businessAccount}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessName"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Customer Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessName}
                show={!!formik.errors.businessName}
                marginLeft={140}
              />
              <input
                type="text"
                id="businessName"
                name="businessName"
                value={formik.values.businessName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                const sanitizedValue = inputValue.replace(/[^0-9A-Za-z\s]/g, "");
                const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                

                  formik.handleChange({
                    target: { name: "businessName", value: capitalizedValue },
                  });
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.businessName && formik.errors.businessName
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Business Name"
              />
              {/* {formik.touched.businessName && formik.errors.businessName && (
                <div className="text-red-500 text-base mt-1">
                  {formik.errors.businessName}
                </div>
              )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessAddress"
                className="block mb-2 text-base font-medium text-gray-900"
              >
                Customer Address{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessAddress}
                show={!!formik.errors.businessAddress}
                marginLeft={130}
              />
              <input
                type="text"
                id="businessAddress"
                name="businessAddress"
                value={formik.values.businessAddress}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const regex = /^[a-zA-Z0-9-/# ]*$/;
                  if (regex.test(inputValue)) {
                    const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1); 
                    formik.handleChange({
                      target: {
                        name: "businessAddress",
                        value: capitalizedValue,
                      },
                    });
                  }
                }}
                
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.businessAddress &&
                  formik.errors.businessAddress
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Business Address"
              />
              {/* {formik.touched.businessAddress &&
                formik.errors.businessAddress && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.businessAddress}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessCity"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                City{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessCity}
                show={!!formik.errors.businessCity}
                marginLeft={155}
              />
              <input
                type="text"
                id="businessCity"
                name="businessCity"
                className={`${
                  formik.touched.businessCity && formik.errors.businessCity
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter City"
                value={formik.values.businessCity}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/[^A-Za-z0-9\s]/g, "");
                  const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                  formik.handleChange({
                    target: { name: "businessCity", value: capitalizedValue },
                  });
                }}
                onBlur={formik.handleBlur}
              />
              {/* {formik.touched.businessCity && formik.errors.businessCity && (
                <div className="text-red-500 text-base mt-1">
                  {formik.errors.businessCity}
                </div>
              )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessState"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                State{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessState}
                show={!!formik.errors.businessState}
                marginLeft={121}
              />
              <select
                id="businessState"
                name="businessState"
                className={`${
                  formik.touched.businessState && formik.errors.businessState
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                value={formik.values.businessState}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select State</option>
                <option value="AL">Alabama (AL)</option>
                <option value="AK">Alaska (AK)</option>
                <option value="AZ">Arizona (AZ)</option>
                <option value="AR">Arkansas (AR)</option>
                <option value="CA">California (CA)</option>
                <option value="CO">Colorado (CO)</option>
                <option value="CT">Connecticut (CT)</option>
                <option value="DE">Delaware (DE)</option>
                <option value="FL">Florida (FL)</option>
                <option value="GA">Georgia (GA)</option>
                <option value="HI">Hawaii (HI)</option>
                <option value="ID">Idaho (ID)</option>
                <option value="IL">Illinois (IL)</option>
                <option value="IN">Indiana (IN)</option>
                <option value="IA">Iowa (IA)</option>
                <option value="KS">Kansas (KS)</option>
                <option value="KY">Kentucky (KY)</option>
                <option value="LA">Louisiana (LA)</option>
                <option value="ME">Maine (ME)</option>
                <option value="MD">Maryland (MD)</option>
                <option value="MA">Massachusetts (MA)</option>
                <option value="MI">Michigan (MI)</option>
                <option value="MN">Minnesota (MN)</option>
                <option value="MS">Mississippi (MS)</option>
                <option value="MO">Missouri (MO)</option>
                <option value="MT">Montana (MT)</option>
                <option value="NE">Nebraska (NE)</option>
                <option value="NV">Nevada (NV)</option>
                <option value="NH">New Hampshire (NH)</option>
                <option value="NJ">New Jersey (NJ)</option>
                <option value="NM">New Mexico (NM)</option>
                <option value="NY">New York (NY)</option>
                <option value="NC">North Carolina (NC)</option>
                <option value="ND">North Dakota (ND)</option>
                <option value="OH">Ohio (OH)</option>
                <option value="OK">Oklahoma (OK)</option>
                <option value="OR">Oregon (OR)</option>
                <option value="PA">Pennsylvania (PA)</option>
                <option value="RI">Rhode Island (RI)</option>
                <option value="SC">South Carolina (SC)</option>
                <option value="SD">South Dakota (SD)</option>
                <option value="TN">Tennessee (TN)</option>
                <option value="TX">Texas (TX)</option>
                <option value="UT">Utah (UT)</option>
                <option value="VT">Vermont (VT)</option>
                <option value="VA">Virginia (VA)</option>
                <option value="WA">Washington (WA)</option>
                <option value="WV">West Virginia (WV)</option>
                <option value="WI">Wisconsin (WI)</option>
                <option value="WY">Wyoming (WY)</option>
              </select>
              {/* {formik.touched.businessState && formik.errors.businessState && (
                <div className="text-red-500 text-base mt-1">
                  {formik.errors.businessState}
                </div>
              )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="businessZipCode"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Zip Code{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.businessZipCode}
                show={!!formik.errors.businessZipCode}
                marginLeft={167}
              />
              {invalidBusinessZipCode === "Invalid number" && !formik.errors.businessZipCode &&  (
              <CustomTooltip1
                content={"Invalid ZipCode"}
                show={"Invalid ZipCode"}
                marginLeft={163}
              />
              )} 

              <input
                className={`${
                  formik.touched.businessZipCode &&
                  formik.errors.businessZipCode
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Zipcode"
                type="number"
                id="businessZipCode"
                name="businessZipCode"
                value={formik.values.businessZipCode}
                onChange={(e) => {handleZipCodeChange("businessZipCode", e)
                const containsNonZero = /[1-9]/.test(e.target.value);
                if(containsNonZero){
                  setInvalidBusinessZipCode("valid")
                }else{
                  setInvalidBusinessZipCode("Invalid number")
                }
            
                if(e.target.value === ""){
                  setInvalidBusinessZipCode("")
                }
                }}
                
                onBlur={formik.handleBlur}
              />
              {/* {formik.touched.businessZipCode &&
                formik.errors.businessZipCode && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.businessZipCode}
                  </div>
                )} */}
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            {areRequiredBusinessFilled() && areRequiredContactFilled() && areRequiredBillingFilled() &&  (
              <>
                <button
                  onClick={() => navigate(`/customers`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  disabled={invalidBusinessAccountNumber === "Invalid number" || invalidBusinessZipCode === "Invalid number"
                  || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" ||
                  invalidShippingPhoneNumber === "Invalid number" || invalidShippingZipCode === "Invalid number" ||
                  invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                  invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    || (isCardSelected && formik.errors.nameOnCard) || (isCardSelected && formik.values.nameOnCard === "") 
                  }
                  
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>

        {/* customer */}
        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
          <div className="flex items-center">
            <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
            <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
              Contact Info
            </h2>
          </div>
          <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="contactPersonName"
                className="block mb-2 text-base font-medium text-gray-900"
              >
                Contact Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.contactPersonName}
                show={!!formik.errors.contactPersonName}
                marginLeft={120}
              />
              

              <input
                type="text"
                id="contactPersonName"
                name="contactPersonName"
                value={formik.values.contactPersonName}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Remove non-alphabetic characters and spaces
                  const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");

                  // Capitalize the first letter of each word
                  const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                    // .toLowerCase()
                    // .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

                  formik.handleChange({
                    target: {
                      name: "contactPersonName",
                      value: capitalizedValue,
                    },
                  });
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.contactPersonName &&
                  formik.errors.contactPersonName
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Name"
              />
              {/* {formik.touched.contactPersonName &&
                formik.errors.contactPersonName && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.contactPersonName}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="contactPersonEmail"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Email{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.contactPersonEmail}
                show={!!formik.errors.contactPersonEmail}
                marginLeft={206}
              />
                <EmailExistTooltip content={emailAlreadyExist} show={!!emailAlreadyExist}   marginLeft={140}/>
                <input
                    type="text"
                    id="contactPersonEmail"
                    name="contactPersonEmail"
                    value={formik.values.contactPersonEmail}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.length > 1) {
                        value = value[0] + value.slice(1).toLowerCase();
                      }
                      formik.setFieldValue('contactPersonEmail', value); // Update Formik state with the modified value
                      setEmailAlreadyExist(""); // Clear the tooltip message
                    }}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.contactPersonEmail && formik.errors.contactPersonEmail
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                    placeholder="Enter Email"
                  />

              {/* {formik.touched.contactPersonEmail &&
                formik.errors.contactPersonEmail && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.contactPersonEmail}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="contactPersonPhoneNumber"
                className="block mb-2 text-base font-medium text-gray-900"
              >
                Phone Number{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.contactPersonPhoneNumber}
                show={!!formik.errors.contactPersonPhoneNumber}
                marginLeft={163}
              />

            {invalidPhoneNumber === "Invalid number" && !formik.errors.contactPersonPhoneNumber &&  (
              <CustomTooltip1
                content={"Invalid Number"}
                show={"Invalid Number"}
                marginLeft={163}
              />
            )}
               
              <ContactNoExistTooltip content={contactNoAlreadyExist} show={!!contactNoAlreadyExist}   marginLeft={140}/>
              <input
                type="tel"
                id="contactPersonPhoneNumber"
                name="contactPersonPhoneNumber"
                value={formik.values.contactPersonPhoneNumber}
                // onChange={(e) =>{handlePhoneNumberChange("contactPersonPhoneNumber", e) }}
                onChange={((e)=>  handlePhoneNumberChange("contactPersonPhoneNumber", e))}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.contactPersonPhoneNumber &&
                  formik.errors.contactPersonPhoneNumber
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Phone Number"
              />
              {/* {formik.touched.contactPersonPhoneNumber &&
                formik.errors.contactPersonPhoneNumber && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.contactPersonPhoneNumber}
                  </div>
                )} */}
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            {areRequiredBusinessFilled() && areRequiredContactFilled() && areRequiredBillingFilled() &&  (
              <>
                <button
                  onClick={() => navigate(`/customers`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  disabled={invalidBusinessAccountNumber === "Invalid number" || invalidBusinessZipCode === "Invalid number"
                  || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" ||
                  invalidShippingPhoneNumber === "Invalid number" || invalidShippingZipCode === "Invalid number" ||
                  invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                  invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    || (isCardSelected && formik.errors.nameOnCard)   || (isCardSelected && formik.values.nameOnCard === "")
                  }
                 
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>

        {/* Billing info */}
        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
          <div className="flex items-center">
            <TbFileInvoice className="text-gray-700 w-6 h-6 mr-2 mb-10" />
            <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
              Billing Info
            </h2>
          </div>
          <div className="grid gap-4 sm:grid-cols-4 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="billingAddress"
                className="block mb-2 text-base font-medium text-gray-900"
              >
                Billing Address{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.billingAddress}
                show={!!formik.errors.billingAddress}
                marginLeft={40}
              />
              <input
                type="text"
                id="billingAddress"
                name="billingAddress"
                value={formik.values.billingAddress}
                onChange={(e) => {
                  const value = e.target.value;
                  let inputValue = value.replace(/[^a-zA-Z0-9\s\-\/#]/g, "");
                  const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1); 
                  formik.handleChange({
                    target: {
                      name: "billingAddress",
                      value: capitalizedValue,
                    },
                  });
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.billingAddress && formik.errors.billingAddress
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter billing Address"
              />
              {/* {formik.touched.billingAddress &&
                formik.errors.billingAddress && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.billingAddress}
                  </div>
                )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="billingCity"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                City{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.billingCity}
                show={!!formik.errors.billingCity}
                marginLeft={65}
              />
              <input
                type="text"
                id="billingCity"
                name="billingCity"
                value={formik.values.billingCity}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/[^A-Za-z0-9\s]/g, "");
                  const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                  formik.handleChange({
                    target: { name: "billingCity", value: capitalizedValue },
                  });
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.billingCity && formik.errors.billingCity
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter City"
              />
              {/* {formik.touched.billingCity && formik.errors.billingCity && (
                <div className="text-red-500 text-base mt-1">
                  {formik.errors.billingCity}
                </div>
              )} */}
            </div>
            <div className="w-full">
              <label
                htmlFor="billlingState"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                State{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.billingState}
                show={!!formik.errors.billingState}
                marginLeft={40}
              />
              <select
                id="billingState"
                name="billingState"
                className={`${
                  formik.touched.billingState && formik.errors.billingState
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                value={formik.values.billingState}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select State</option>
                <option value="AL">Alabama (AL)</option>
                <option value="AK">Alaska (AK)</option>
                <option value="AZ">Arizona (AZ)</option>
                <option value="AR">Arkansas (AR)</option>
                <option value="CA">California (CA)</option>
                <option value="CO">Colorado (CO)</option>
                <option value="CT">Connecticut (CT)</option>
                <option value="DE">Delaware (DE)</option>
                <option value="FL">Florida (FL)</option>
                <option value="GA">Georgia (GA)</option>
                <option value="HI">Hawaii (HI)</option>
                <option value="ID">Idaho (ID)</option>
                <option value="IL">Illinois (IL)</option>
                <option value="IN">Indiana (IN)</option>
                <option value="IA">Iowa (IA)</option>
                <option value="KS">Kansas (KS)</option>
                <option value="KY">Kentucky (KY)</option>
                <option value="LA">Louisiana (LA)</option>
                <option value="ME">Maine (ME)</option>
                <option value="MD">Maryland (MD)</option>
                <option value="MA">Massachusetts (MA)</option>
                <option value="MI">Michigan (MI)</option>
                <option value="MN">Minnesota (MN)</option>
                <option value="MS">Mississippi (MS)</option>
                <option value="MO">Missouri (MO)</option>
                <option value="MT">Montana (MT)</option>
                <option value="NE">Nebraska (NE)</option>
                <option value="NV">Nevada (NV)</option>
                <option value="NH">New Hampshire (NH)</option>
                <option value="NJ">New Jersey (NJ)</option>
                <option value="NM">New Mexico (NM)</option>
                <option value="NY">New York (NY)</option>
                <option value="NC">North Carolina (NC)</option>
                <option value="ND">North Dakota (ND)</option>
                <option value="OH">Ohio (OH)</option>
                <option value="OK">Oklahoma (OK)</option>
                <option value="OR">Oregon (OR)</option>
                <option value="PA">Pennsylvania (PA)</option>
                <option value="RI">Rhode Island (RI)</option>
                <option value="SC">South Carolina (SC)</option>
                <option value="SD">South Dakota (SD)</option>
                <option value="TN">Tennessee (TN)</option>
                <option value="TX">Texas (TX)</option>
                <option value="UT">Utah (UT)</option>
                <option value="VT">Vermont (VT)</option>
                <option value="VA">Virginia (VA)</option>
                <option value="WA">Washington (WA)</option>
                <option value="WV">West Virginia (WV)</option>
                <option value="WI">Wisconsin (WI)</option>
                <option value="WY">Wyoming (WY)</option>
              </select>
              {/* {formik.touched.billingState && formik.errors.billingState && (
                <div className="text-red-500 text-base mt-1">
                  {formik.errors.billingState}
                </div>
              )} */}
            </div>

            <div className="w-full">
              <label
                htmlFor="billingZipCode"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Zip Code{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1
                content={formik.errors.billingZipCode}
                show={!!formik.errors.billingZipCode}
                marginLeft={80}
              />
              {invalidBillingZipCode === "Invalid number" && !formik.errors.billingZipCode &&  (
              <CustomTooltip1
                content={"Invalid ZipCode"}
                show={"Invalid ZipCode"}
                marginLeft={163}
              />
           )} 
              <input
                className={`${
                  formik.touched.billingZipCode && formik.errors.billingZipCode
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                placeholder="Enter Zipcode"
                type="text"
                id="billingZipCode"
                name="billingZipCode"
                value={formik.values.billingZipCode}
                onChange={(e) => {handleZipCodeChange("billingZipCode", e)
                const containsNonZero = /[1-9]/.test(e.target.value);
                if(containsNonZero){
                  setInvalidBillingZipCode("valid")
                }else{
                  setInvalidBillingZipCode("Invalid number")
                }
            
                if(e.target.value === ""){
                  setInvalidBillingZipCode("")
                }
                }}
                onBlur={formik.handleBlur}
              />
              {/* {formik.touched.billingZipCode &&
                formik.errors.billingZipCode && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.billingZipCode}
                  </div>
                )} */}
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            {areRequiredBusinessFilled() && areRequiredContactFilled() && areRequiredBillingFilled() && (
              <>
                <button
                  onClick={() => navigate(`/customers`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  disabled={invalidBusinessAccountNumber === "Invalid number" || invalidBusinessZipCode === "Invalid number"
                  || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" ||
                  invalidShippingPhoneNumber === "Invalid number" || invalidShippingZipCode === "Invalid number" ||
                  invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                  invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    || (isCardSelected && formik.errors.nameOnCard) || (isCardSelected && formik.values.nameOnCard === "")
                  }
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>

        {/* shipping info */}
        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex items-center justify-between">
              <div className="flex">
                <MdOutlineLocalShipping className="text-gray-700 w-6 h-6 mr-2 mb-10" />
                  <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                    Shipping Info
                  </h2>
              </div>

            <div className="flex mb-6 justify-end items-end">
              <span className="text-gray-700 font-semibold">Manual</span>
              <label className="switch-toggle ml-4">
                
                <input
                  type="checkbox"
                  onChange={handleAddressToggle}
                  checked={isSameAsAboveSelected}
                  id="sameAsAbove"
                  name="sameAsAbove"
                />
                <span className="slider-toggle round"></span>
              </label>
              <span className="ml-4 font-semibold">Same as above</span>
            </div>
          </div>

         

          <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
                <div class="w-full sm:grid-cols-span-3">
                  
                  <label
                    htmlFor="shippingTo"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Shipping To
                  </label>
                  <CustomTooltip1
                    content={formik.errors.shippingTo}
                    show={!!formik.errors.shippingTo}
                    marginLeft={308}
                  />
                  <input
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Name who will receive the delivery"
                    type="text"
                    id="shippingTo"
                    name="shippingTo"
                    // value={formik.values.shippingTo}
                    value={shippingInfo.contactPersonName}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");
                      const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1); 
                      setShippingInfo((prev) => ({
                        ...prev,
                        contactPersonName: capitalizedValue,
                      }));
                    }}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.shippingTo && formik.errors.shippingTo
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  />
                  {/* {formik.touched.shippingTo && formik.errors.shippingTo && (
                    <div className="text-red-500 text-base mt-1">
                      {formik.errors.shippingTo}
                    </div>
                  )} */}
                </div>
                
                <div class="w-full sm:grid-cols-span-3">
                  <label
                    htmlFor="shippingContactNumber"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Contact Number
                  </label>
                  {/* <CustomTooltip1
                    content={"Invalid number"}
                    show={formik.errors.shippingContactNumber}
                    marginLeft={344}
                  /> */}
                  {invalidShippingPhoneNumber === "Invalid number" && formik.errors.shippingContactNumber &&  (
                  <CustomTooltip1
                    content={"Invalid Number"}
                    show={"Invalid Number"}
                    marginLeft={163}
                  />
                    )} 

                  <input
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Contact Number"
                    type="text"
                    id="shippingContactNumber"
                    name="shippingContactNumber"
                    value={shippingInfo.contactPersonPhoneNumber}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      
                      const containsNonZero = /[1-9]/.test(inputValue);
                      
                      if (inputValue === "") {
                        setInvalidShippingPhoneNumber("");
                      } else if (containsNonZero && inputValue.length >= 10) {
                        setInvalidShippingPhoneNumber("valid");
                      } else {
                        setInvalidShippingPhoneNumber("Invalid number");
                      }
                    
                      setShippingInfo((prev) => ({
                        ...prev,
                        contactPersonPhoneNumber: formatPhoneNumber(inputValue), // Store the sanitized value
                      }));
                    }}
                    
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.shippingContactNumber &&
                      formik.errors.shippingContactNumber
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  />
                </div>
                
                <div className="w-full sm:grid-cols-span-3">
                  <label
                    htmlFor="shippingAddress"
                    className="block mb-2 text-base font-medium text-gray-900"
                  >
                    Shipping Address
                  </label>
                  <CustomTooltip1
                    content={formik.errors.shippingAddress}
                    show={!!formik.errors.shippingAddress}
                    marginLeft={43}
                  />
                  <input
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Shipping Address"
                    type="text"
                    id="shippingAddress"
                    name="shippingAddress"
                    value={shippingInfo.billingAddress}
                    onChange={(e) => {
                      let inputValue = e.target.value
                      inputValue = inputValue.replace(/[^a-zA-Z0-9\s\-\/#]/g, "");
                      const capitalizedValue = inputValue.replace(/\b\w/g, char => char.toUpperCase());
                      setShippingInfo((prev) => ({
                        ...prev,
                        billingAddress: capitalizedValue,
                      }));
                    }}
                    
                    
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.shippingAddress &&
                      formik.errors.shippingAddress
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  />
                  {/* {formik.touched.shippingAddress &&
                    formik.errors.shippingAddress && (
                      <div className="text-red-500 text-base mt-1">
                        {formik.errors.shippingAddress}
                      </div>
                    )} */}
                </div>
            </div> 

          <div class="grid gap-4 sm:grid-cols-4 sm:gap-6 mt-6">
                <div className="w-full">
                  <label
                    htmlFor="shippingCity"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    City
                  </label>
                  <CustomTooltip1
                    content={formik.errors.shippingCity}
                    show={!!formik.errors.shippingCity}
                    marginLeft={67}
                  />
                  <input
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter City"
                    type="text"
                    id="shippingCity"
                    name="shippingCity"
                    value={shippingInfo.billingCity}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/[^A-Za-z0-9\s]/g, "")
                      const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                      setShippingInfo((prev) => ({
                          ...prev,
                          billingCity: capitalizedValue ,
                        }));
                    }}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.shippingCity && formik.errors.shippingCity
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  />
                  {/* {formik.touched.shippingCity && formik.errors.shippingCity && (
                    <div className="text-red-500 text-base mt-1">
                      {formik.errors.shippingCity}
                    </div>
                  )} */}
                </div>

                <div>
                  <label
                    htmlFor="shippingState"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    State
                  </label>
                  <CustomTooltip1
                    content={formik.errors.shippingState}
                    show={!!formik.errors.shippingState}
                    marginLeft={121}
                  />
                  <select
                    id="shippingState"
                    name="shippingState"
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    value={shippingInfo.billingState}
                    onChange={(e) => {
                      
                    
                      setShippingInfo((prev) => ({
                        ...prev,
                        billingState: e.target.value,
                      }));
                    }}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.shippingState && formik.errors.shippingState
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  >
                    <option value="">Select State</option>
                    <option value="AL">Alabama (AL)</option>
                    <option value="AK">Alaska (AK)</option>
                    <option value="AZ">Arizona (AZ)</option>
                    <option value="AR">Arkansas (AR)</option>
                    <option value="CA">California (CA)</option>
                    <option value="CO">Colorado (CO)</option>
                    <option value="CT">Connecticut (CT)</option>
                    <option value="DE">Delaware (DE)</option>
                    <option value="FL">Florida (FL)</option>
                    <option value="GA">Georgia (GA)</option>
                    <option value="HI">Hawaii (HI)</option>
                    <option value="ID">Idaho (ID)</option>
                    <option value="IL">Illinois (IL)</option>
                    <option value="IN">Indiana (IN)</option>
                    <option value="IA">Iowa (IA)</option>
                    <option value="KS">Kansas (KS)</option>
                    <option value="KY">Kentucky (KY)</option>
                    <option value="LA">Louisiana (LA)</option>
                    <option value="ME">Maine (ME)</option>
                    <option value="MD">Maryland (MD)</option>
                    <option value="MA">Massachusetts (MA)</option>
                    <option value="MI">Michigan (MI)</option>
                    <option value="MN">Minnesota (MN)</option>
                    <option value="MS">Mississippi (MS)</option>
                    <option value="MO">Missouri (MO)</option>
                    <option value="MT">Montana (MT)</option>
                    <option value="NE">Nebraska (NE)</option>
                    <option value="NV">Nevada (NV)</option>
                    <option value="NH">New Hampshire (NH)</option>
                    <option value="NJ">New Jersey (NJ)</option>
                    <option value="NM">New Mexico (NM)</option>
                    <option value="NY">New York (NY)</option>
                    <option value="NC">North Carolina (NC)</option>
                    <option value="ND">North Dakota (ND)</option>
                    <option value="OH">Ohio (OH)</option>
                    <option value="OK">Oklahoma (OK)</option>
                    <option value="OR">Oregon (OR)</option>
                    <option value="PA">Pennsylvania (PA)</option>
                    <option value="RI">Rhode Island (RI)</option>
                    <option value="SC">South Carolina (SC)</option>
                    <option value="SD">South Dakota (SD)</option>
                    <option value="TN">Tennessee (TN)</option>
                    <option value="TX">Texas (TX)</option>
                    <option value="UT">Utah (UT)</option>
                    <option value="VT">Vermont (VT)</option>
                    <option value="VA">Virginia (VA)</option>
                    <option value="WA">Washington (WA)</option>
                    <option value="WV">West Virginia (WV)</option>
                    <option value="WI">Wisconsin (WI)</option>
                    <option value="WY">Wyoming (WY)</option>
                  </select>
                  {/* {formik.touched.shippingState && formik.errors.shippingState && (
                    <div className="text-red-500 text-base mt-1">
                      {formik.errors.shippingState}
                    </div>
                  )} */}
                </div>

                <div>
                  <label
                    htmlFor="shippingZipCode"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Zip Code
                  </label>
                  <CustomTooltip1
                    content={formik.errors.shippingZipCode}
                    show={!!formik.errors.shippingZipCode}
                    marginLeft={79}
                  />
                  {invalidShippingZipCode === "Invalid number" && !formik.errors.shippingZipCode &&  (
                  <CustomTooltip1
                    content={"Invalid ZipCode"}
                    show={"Invalid ZipCode"}
                    marginLeft={163}
                  />
                  )} 

                  <input
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter ZipCode"
                    type="text"
                    id="shippingZipCode"
                    name="shippingZipCode"
                    value={shippingInfo.billingZipCode}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                      const finalValue = sanitizedValue.slice(0, 5);
                      const containsNonZero = /[1-9]/.test(finalValue);
        
                        if(containsNonZero){
                          setInvalidShippingZipCode("valid")
                        }else{
                          setInvalidShippingZipCode("Invalid number")
                        }
                        if(finalValue === ""){
                          setInvalidShippingZipCode("")
                        }
                    
                      setShippingInfo((prev) => ({
                        ...prev,
                        billingZipCode: finalValue,
                      }));
                    }}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.shippingZipCode &&
                      formik.errors.shippingZipCode
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  />
                  {/* {formik.touched.shippingZipCode &&
                    formik.errors.shippingZipCode && (
                      <div className="text-red-500 text-base mt-1">
                        {formik.errors.shippingZipCode}
                      </div>
                    )} */}
                </div>

                <div>
                  <label
                    htmlFor="shippingCounty"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    County
                  </label>
                  {/* <CustomTooltip1
                    content={formik.errors.shippingZipCode}
                    show={!!formik.errors.shippingZipCode}
                    marginLeft={79}
                  />
                  {invalidShippingZipCode === "Invalid number" && !formik.errors.shippingZipCode &&  (
                  <CustomTooltip1
                    content={"Invalid ZipCode"}
                    show={"Invalid ZipCode"}
                    marginLeft={163}
                  />
                  )}  */}

                  <input
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter County"
                    type="text"
                    id="shippingCounty"
                    name="shippingCounty"
                    value={shippingInfo.shippingCounty}
                    
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/[^0-9A-Za-z]/g, "");
                      const finalValue = sanitizedValue.slice(0, 20)
                      const containsNonZero = /[1-9]/.test(inputValue);

                        // if(containsNonZero){
                        //   setInvalidShippingZipCode("valid")
                        // }else{
                        //   setInvalidShippingZipCode("Invalid number")
                        // }
                        // if(finalValue === ""){
                        //   setInvalidShippingZipCode("")
                        // }        
                    
                      setShippingInfo((prev) => ({
                        ...prev,
                        shippingCounty: finalValue,
                      }));

                      // console.log("---" , shippingInfo);
                    }}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.shippingZipCode &&
                      formik.errors.shippingZipCode
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  />
                  {/* {formik.touched.shippingZipCode &&
                    formik.errors.shippingZipCode && (
                      <div className="text-red-500 text-base mt-1">
                        {formik.errors.shippingZipCode}
                      </div>
                    )} */}
                </div>
          </div>

            <div className="sm:col-span-4 mb-8 mt-6">
              <label
                htmlFor="shippingDeliveryInstructions"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Delivery Instructions
              </label>
              <CustomTooltip1
                content={formik.errors.shippingDeliveryInstructions}
                show={!!formik.errors.shippingDeliveryInstructions}
                marginLeft={824}
              />
              <textarea
                rows="4"
                class="block p-2.5 w-full text-base text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                placeholder="Enter Delivery Instructions if any..."
                id="shippingDeliveryInstructions"
                name="shippingDeliveryInstructions"
                value={formik.values.shippingDeliveryInstructions}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Remove non-alphabetic characters and spaces
                  const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");

                  // Capitalize the first letter of each word
                  const capitalizedValue = sanitizedValue
                    .toLowerCase()
                    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

                  formik.handleChange({
                    target: {
                      name: "shippingDeliveryInstructions",
                      value: capitalizedValue,
                    },
                  });
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.shippingDeliveryInstructions &&
                  formik.errors.shippingDeliveryInstructions
                    ? "border-red-500"
                    : "border-gray-300"
                } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
              ></textarea>
              {/* {formik.touched.shippingDeliveryInstructions &&
                formik.errors.shippingDeliveryInstructions && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.shippingDeliveryInstructions}
                  </div>
                )} */}
            </div>
       

          <div className="flex justify-end space-x-3">
            {areRequiredBusinessFilled() && areRequiredContactFilled() && areRequiredBillingFilled() &&  areRequiredshippingFilled() && (
              <>
                <button
                  onClick={() => navigate(`/customers`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  disabled={invalidBusinessAccountNumber === "Invalid number" || invalidBusinessZipCode === "Invalid number"
                  || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" ||
                  invalidShippingPhoneNumber === "Invalid number" || invalidShippingZipCode === "Invalid number" ||
                  invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                  invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                   || (isCardSelected && formik.values.nameOnCard === "")
                  }
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>

        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
          <div className="flex justify-between">
            <div className="flex items-center">
              <MdOutlinePayment className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Payment Info
              </h2>
            </div>
            <div className="flex items-center mb-6">
              <span className="text-gray-700 font-semibold">Bank</span>
              <label className="switch-toggle ml-4">
                
                <input
                  type="checkbox"
                  onChange={handleToggle}
                  checked={isCardSelected}
                  id="paymentType"
                  name="paymentType"
                />
                <span className="slider-toggle round"></span>
              </label>
              <span className="ml-4 font-semibold">Card</span>
            </div>
          </div>

          {isCardSelected ? (
            <div class="grid gap-4 sm:grid-cols-4 sm:gap-6">
              <div class="w-full sm:col-span-1">
                <label
                  htmlFor="cardPaymentCurrency"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Currency
                </label>
                

                <select
                  id="cardPaymentCurrency"
                  name="cardPaymentCurrency"
                  value={formik.values.cardPaymentCurrency}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.cardPaymentCurrency &&
                    formik.errors.cardPaymentCurrency
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Currency</option>
                  <option value="USD">USD ($) - United States dollar</option>
                  <option value="CAD">CAD ($) Canadian dollar</option>
                </select>
                {formik.touched.cardPaymentCurrency &&
                  formik.errors.cardPaymentCurrency && (
                    <div className="text-red-500 text-base mt-1">
                      {formik.errors.cardPaymentCurrency}
                    </div>
                  )}
              </div>

              <div class="w-full sm:col-span-1">
                <label
                  htmlFor="creditCardName"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Credit Card
                </label>
                <CustomTooltip1
                content={formik.errors.creditCardName}
                show={!!formik.errors.creditCardName}
                marginLeft={190}
              />
                <select
                  id="creditCardName"
                  name="creditCardName"
                  value={formik.values.creditCardName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.creditCardName &&
                    formik.errors.creditCardName
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Card Type</option>
                  <option value="Americal Express">American Express</option>
                  <option value="Bank of America">Bank of America</option>
                  <option value="Barclays">Barclays</option>
                  <option value="Capital One">Capital One</option>
                  <option value="Chase">Chase</option>
                  <option value="Citi">Citi</option>
                  <option value="Discover">Discover</option>
                  <option value="Synchrony">Synchrony</option>
                  <option value="US Bank">U.S. Bank</option>
                  <option value="Wells Fargo">Wells Fargo</option>
                </select>
                {/* {formik.touched.creditCardName &&
                  formik.errors.creditCardName && (
                    <div className="text-red-500 text-base mt-1">
                      {formik.errors.creditCardName}
                    </div>
                  )} */}
              </div>

              <div className="flex sm:col-span-2 gap-3 ">
                <div class="w-full">
                  <label
                    htmlfor="nameOnCard"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Name on the Card
                  </label>
                  <CustomTooltip1
                    content={formik.errors.nameOnCard}
                    show={!!formik.errors.nameOnCard}
                    marginLeft={281}
                  />
                  <input
                    type="text"
                    id="nameOnCard"
                    name="nameOnCard"
                    value={formik.values.nameOnCard}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");
                      const capitalizedValue = sanitizedValue.replace(/\b\w/g, char => char.toUpperCase());
                      formik.handleChange({
                        target: { name: "nameOnCard", value: capitalizedValue },
                      });
                    }}
                    
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched.nameOnCard && formik.errors.nameOnCard
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter exact name shown on the card"
                  />
                  {/* {formik.touched.nameOnCard && formik.errors.nameOnCard && (
                    <div className="text-red-500 text-base mt-1">
                      {formik.errors.nameOnCard}
                    </div>
                  )} */}
                </div>
              </div>

              <div>
                <label
                  htmlFor="cardNumber"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Card #
                </label>
                <CustomTooltip1
                content={formik.errors.cardNumber}
                show={!!formik.errors.cardNumber}
                marginLeft={46}
                />
                {invalidCardNumber === "Invalid number" && !formik.errors.cardNumber &&  (
                <CustomTooltip1
                content={"Invalid Card Number"}
                show={"Invalid Card Number"}
                marginLeft={163}
                    />
                )} 
                <input
                  type="text"
                  id="cardNumber"
                  name="cardNumber"
                  value={formik.values.cardNumber}
                  onChange={handleCardNumberChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.cardNumber && formik.errors.cardNumber
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Card Number"
                />
                {/* {formik.touched.cardNumber && formik.errors.cardNumber && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.cardNumber}
                  </div>
                )} */}
              </div>

              <div>
              <label
                htmlFor="cardExpiryDate"
                className="block mb-2 text-base font-medium text-gray-900"
              >
                Exp Date
              </label>
              <div className="relative">
              <input
                type="text"
                id="cardExpiryDate"
                name="cardExpiryDate"
                value={formik.values.cardExpiryDate}
                onChange={handleExpirationDateChange}
                // onChange={(e) => {
                //   const inputValue = e.target.value;

                //   formik.setFieldValue("cardExpiryDate", inputValue);
                // }}
                onFocus={() => setCalendarOpen(true)}
                className={`${
                  formik.touched.cardExpiryDate && formik.errors.cardExpiryDate
                    ? "border-red-500"
                    : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  placeholder="MM/YY"
                />

              {calendarOpen && (
                <Calendar
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date)) {
                      const formattedDate = date.toLocaleDateString("en-US", {
                        year: "2-digit",
                        month: "2-digit",
                      });
                      handleExpirationDateChange({ target: { name: "cardExpiryDate", value: formattedDate } });
                    }
                  }}
                  value={isValidDate(formik.cardExpiryDate) ? new Date(formik.cardExpiryDate) : null}
                  showYearDropdown
                  showMonthYearDropdown
                  yearDropdownItemNumber={10}
                  minDate={new Date()} // Set the minimum date to the current date
                  onClickDay={() => setCalendarOpen(false)} // Close calendar when a day is clicked
                />
              )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="cardCVV"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  CVV #
                </label>
                <CustomTooltip1
                content={formik.errors.cardCVV}
                show={!!formik.errors.cardCVV}
                marginLeft={90}
                />
                {invalidCardCvv === "Invalid number" && !formik.errors.cardCVV &&  (
              <CustomTooltip1
                content={"Invalid Cvv"}
                show={"Invalid Cvv"}
                marginLeft={163}
               />
              )} 
                <input
                  type="text"
                  id="cardCVV"
                  name="cardCVV"
                  value={formik.values.cardCVV}
                  onChange={handleCardCVVChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.cardCVV && formik.errors.cardCVV
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter CVV"
                />
              </div>

              <div>
                <label
                  htmlFor="cardZipCode"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Zip Code
                </label>
                <CustomTooltip1
                content={formik.errors.cardZipCode}
                show={!!formik.errors.cardZipCode}
                marginLeft={80}
              />
              {invalidCardZipCode === "Invalid number" && !formik.errors.cardZipCode &&  (
              <CustomTooltip1
                content={"Invalid Number"}
                show={"Invalid Number"}
                marginLeft={163}
              />
           )} 
             
                <input
                  type="text"
                  id="cardZipCode"
                  name="cardZipCode"
                  value={formik.values.cardZipCode}
                  onChange={(e) => {handleZipCodeChange("cardZipCode", e)
                  const containsNonZero = /[1-9]/.test(e.target.value);
                  if(containsNonZero){
                    setInvalidCardZipCode("valid")
                  }else{
                    setInvalidCardZipCode("Invalid number")
                  }
              
                  if(e.target.value === ""){
                    setInvalidCardZipCode("")
                  }
                  }}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.cardZipCode && formik.errors.cardZipCode
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Zip Code"
                />
                {/* {formik.touched.cardZipCode && formik.errors.cardZipCode && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.cardZipCode}
                  </div>
                )} */}
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-6">
              <div class="w-full sm:col-span-2">
                <label
                  htmlFor="bankCurrency"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Currency
                </label>

                <select
                  id="bankCurrency"
                  name="bankCurrency"
                  value={formik.values.bankCurrency}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.bankCurrency && formik.errors.bankCurrency
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Currency</option>
                  <option value="USD">USD ($) - United States dollar</option>
                  <option value="CAD">CAD ($) Canadian dollar</option>
                </select>
                {formik.touched.bankCurrency && formik.errors.bankCurrency && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.bankCurrency}
                  </div>
                )}
              </div>

              <div class="w-full">
                <label
                  htmlFor="bankRouting"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Bank Routing #
                </label>
                <CustomTooltip1
                content={formik.errors.bankRouting}
                show={!!formik.errors.bankRouting}
                marginLeft={334}
              />
              {invalidBankRoutingNumber === "Invalid number" && !formik.errors.bankRouting &&  (
              <CustomTooltip1
                content={"Invalid Number"}
                show={"Invalid Number"}
                marginLeft={163}
              />
           )} 

                <input
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Routing Number"
                  type="text"
                  id="bankRouting"
                  name="bankRouting"
                  value={formik.values.bankRouting}
                  onChange={handleBankRoutingChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.bankRouting && formik.errors.bankRouting
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                />
                {/* {formik.touched.bankRouting && formik.errors.bankRouting && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.bankRouting}
                  </div>
                )} */}
              </div>

              <div class="w-full">
                <label
                  htmlFor="bankAccount"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Bank Account #
                </label>
                <CustomTooltip1
                content={formik.errors.bankAccount}
                show={!!formik.errors.bankAccount}
                marginLeft={300}
                />
                {invalidBankAccountNumber === "Invalid number" && !formik.errors.bankAccount &&  (
               <CustomTooltip1
                content={"Invalid Number"}
                show={"Invalid Number"}
                marginLeft={163}
               />
                )} 

                <input
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Account Number"
                  type="text"
                  id="bankAccount"
                  name="bankAccount"
                  value={formik.values.bankAccount}
                  onChange={(e) => handleAccountNumberChange("bankAccount", e)}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched.bankAccount && formik.errors.bankAccount
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-white border text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                />
                {/* {formik.touched.bankAccount && formik.errors.bankAccount && (
                  <div className="text-red-500 text-base mt-1">
                    {formik.errors.bankAccount}
                  </div>
                )} */}
              </div>
            </div>
          )}
          <div className="flex justify-end space-x-3 mt-6">
            { areRequiredBusinessFilled() && areRequiredContactFilled() && areRequiredBillingFilled() &&  areRequiredpaymentFilled() && (
              <>
                <button
                  onClick={() => navigate(`/customers`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={invalidBusinessAccountNumber === "Invalid number" || invalidBusinessZipCode === "Invalid number"
                  || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" ||
                  invalidShippingPhoneNumber === "Invalid number" || invalidShippingZipCode === "Invalid number" ||
                  invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                  invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                  || (isCardSelected && formik.values.nameOnCard === "")
                  }
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default CustomerData;
