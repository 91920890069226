import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { deleteInvoice } from "../../store/invoiceSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import { showToastForDeleteInvoice } from "../toasts/toastForInvoice";


export default function PopUpModal({ onClose, invoiceNumber, invoiceDelete, invoiceName , resetRowSelection}) {

    const dispatch = useDispatch();
    const businessId = localStorage.getItem("business_id")
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const [openModal, setOpenModal] = useState(true);

  const handleDelete = async() => {
    try{
        const response = await axios.delete(`${baseURL}/${btoa("invoice/delete")}/${btoa(invoiceNumber)}/${btoa(businessId)}`)
        console.log("delete invoice" , response)
        if (response.data && response.data.deleteStatus === true) {
            dispatch(deleteInvoice(invoiceNumber));
            window.scroll(0,0)
            onClose();
            resetRowSelection();
            showToastForDeleteInvoice(`${invoiceName} deleted successfully`, "error" , "")
            
        } else {
            console.error("Failed to delete the invoice on the server.");
        }
    }catch(error){
        console.log("error deleting invoice" , error);
    }
    
  };

  return (
    <Modal show={openModal} size="md" popup onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
            Are you sure you want to delete
            <span className="block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">" {invoiceName} "</span>
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={handleDelete}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={onClose}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
