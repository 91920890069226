import React from 'react';

const CustomTooltip = ({ content, show }) => {
  return (
    <div
    style={{
      display: show ? 'block' : 'none',
      position: 'absolute',
      backgroundColor: '#ff4d4f',
      color: '#fff',
      padding: '5px',
      borderRadius: '4px',
      marginTop: '-44px',
      marginLeft:'200px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    }}
  >
    <div
      style={{
        position: 'absolute',
        bottom: '-10px',
        left: '50%',
        marginLeft: '-10px',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #ff4d4f',
      }}
    ></div>
    {content}
  </div>
  );
};

export default CustomTooltip;
