import React, { useState } from "react";
import { routes } from "../components/constants/routes.constants";

import { HiGift, HiOutlineGift, HiOutlineSquares2X2 } from "react-icons/hi2";
import PropTypes from "prop-types";
import { Navbar } from "flowbite-react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Typography,
} from "@material-tailwind/react";
import { FaMoneyBillAlt, FaProductHunt, FaThLarge, FaUser, FaUserSecret } from "react-icons/fa";
import { CgGift } from "react-icons/cg";
import NotificationBell from "./pages/Notification";
import { IoBookOutline, IoBookSharp, IoSettings, IoSettingsOutline, IoSettingsSharp } from "react-icons/io5";
import { BiSolidUser } from "react-icons/bi";
import { AiFillWindows, AiOutlineWindows } from "react-icons/ai";
import { Link, useNavigate , useLocation, Routes, useParams} from "react-router-dom";
import { BsBell, BsFillBellFill } from "react-icons/bs";
import { useEffect } from "react";
import { BiSolidBusiness } from "react-icons/bi";
import { matchPath } from 'react-router-dom';
import axios from "axios";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi2";
import { useUser } from "./pages/UserContext";
import { RiSortAsc } from "react-icons/ri";
import { ImPriceTags } from "react-icons/im";
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import config  from "../config/config"



Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
function Header({ toggleSidebar , handleSignOut }) {

  

  // console.log('Traf name:', user.contact_name);
  // console.log('Traf email:', user.contact_email);

  const contact_name = localStorage.getItem("userName")
  const contact_email = localStorage.getItem("userEmail")

  // console.log("userName :" , contact_name , "userEmail:" , contact_email);


  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isBookHovered, setIsBookHovered] = useState(false);
  const [isGiftHovered, setIsGiftHovered] = useState(false);
  const [isWindowsHovered, setIsWindowsHovered] = useState(false);
  const [isBellHovered , setIsBellHovered] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [businessType , setBusinessType] = useState("");
  const [userRole, setUserRole] = useState('');
  const [scrollingMessage ,setScrollingMessage] = useState('')

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const isCurrentRoute = (route) => location.pathname === route;
  const handleSettingIconClick = () => {
    setShowMenu(true);
  };

  const handleMenuItemClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
   
    const storedUserRole = localStorage.getItem("user_role");
    setUserRole(storedUserRole); 
  }, []); 


  // console.log("user_role" ,userRole);

  const handleMouseEnter = (e) => {
    e.stopPropagation(); 
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterSetting = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveSetting = () => {
    setIsHovered(false);
  };

  const handleBookMouseEnter =()=>{
    setIsBookHovered(true)
  }
  const handleBookMouseLeave =()=>{
    setIsBookHovered(false)
  }

  const handleGiftMouseEnter =()=>{
    setIsGiftHovered(true)
  }
  const handleGiftMouseLeave =()=>{
    setIsGiftHovered(false)
  }

  const handleWindowsMouseEnter=()=>{
    setIsWindowsHovered(true)
  }
  const handleWindowsMouseLeave=()=>{
    setIsWindowsHovered(false)
  }
  const handleBellMouseEnter =()=>{
    setIsBellHovered(true);
  }
  const handleBellMouseLeave=()=>{
    setIsBellHovered(false);
  }

  // const {businessId}  = useParams()
  // console.log(businessId);

    const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  // console.log("user role is" , user_role);
  // console.log("bus id is " , business_id);
  // console.log("user id is " , user_id);
  
  const [businessData , setBusinessData] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
    .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        // console.log("response " , response);
        // console.log("Business Data:", response.data.business_data);
        // console.log("Business type:", response.data.business_data[0].business_type);
        // console.log("Business Data length :" , response.data.business_data.length);
        setBusinessData(response.data.business_data);
        setBusinessType(response.data.business_data[0].business_type)
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
       ;
      });
     
  }, []);

  // useEffect(()=> {
  //   const fetchData = async()=>{
  //     try {
  //       const response  = await axios.get(`${baseURL}${btoa("scrollingmsg/fetchscrollmsgforbusinesses")}/${btoa(business_id)}`)
  //       setScrollingMessage(response.data.scrollmsg_data[0].message)
  //       console.log("scrolling message" , response);
  //     } catch (error) {
  //       console.log("error fetching scrolling messages" , error);
  //     }   
  //   }
  //   fetchData()
  // },[])

  
  const businessDataLength = businessData.length
  // console.log("length is :" , businessDataLength);
  
  



 
  useEffect(() => {
    
    setIsSetting(location.pathname === "/products" || location.pathname === "/items" || location.pathname === "/business"
    || location.pathname === "/products/productform" || location.pathname === "/items/itemform" || location.pathname === "/business"
    || location.pathname === "/businessdashboard"  || location.pathname === "/viewbusinessusers" ||
    location.pathname === "/itemprice" || location.pathname === "/viewBusiness" || location.pathname === "/setup/viewitem" ||
    location.pathname.includes("/customers") || location.pathname.includes("/vendors") || location.pathname.includes("/viewvendor")
    // || location.pathname === `/setup/viewproduct/${productId}`
     ||  location.pathname.startsWith("/setup/viewitem/") 
    );
  }, [location.pathname]);



  return (
    <>
      <header className="bg-[white] shadow h-14 flex  items-center justify-between  px-4 sticky top-0 z-50">
        <div>
          {/* <HiOutlineSquares2X2
            className="w-6 h-6 cursor-pointer hover:text-[#3479E0]"
            onClick={toggleSidebar}
          /> */}
        </div>

        <div className="flex items-center space-x-2 mr-4">


            <div
              onMouseEnter={handleBookMouseEnter}
              onMouseLeave={handleBookMouseLeave}
              style={{
                backgroundColor: isBookHovered ? "#cddbfe" : "transparent",
                color: isBookHovered ? "#5850ec" : "gray",
                display: "inline-block",
                padding: "10px",
                borderRadius: "50%",
                transition: "all 0.3s ease",
              
              }}
            >
              {isBookHovered ? (
                <IoBookSharp size={20} />
              ) : (
                <IoBookOutline size={20} />
              )}
            </div>

          
          



          {/* <CgGift
            className="text-gray-400 hover:text-purple-600 hover:bg-purple-200 rounded-full p-2 text-blue-600"
            size={37}
          /> */}


            <div
                  onMouseEnter={handleGiftMouseEnter}
                  onMouseLeave={handleGiftMouseLeave}
                  style={{
                    backgroundColor: isGiftHovered ? "#dcd7fe" : "transparent",
                    color: isGiftHovered ? "#7e3af2" : "gray",
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                
                  }}
                >
                  {isGiftHovered ? (
                    <HiGift  size={20} />
                  ) : (
                    <HiOutlineGift size={20} />
                  )}
            </div>



            
            <Menu>
              <MenuHandler>
                <div>
                  <div
                    onClick={handleSettingIconClick}
                    onMouseEnter={handleMouseEnterSetting}
                    onMouseLeave={handleMouseLeaveSetting}
                    style={{
                      backgroundColor: (isHovered || isSetting) ? "#C3DDFD" : "transparent",
                      color: (isHovered || isSetting) ? "#1C64F2" : "gray",
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "50%",
                      transition: "all 0.3s ease",
                      marginTop: "6px"
                    }}
                  >
                    {isHovered || isSetting ? <IoSettings size={20} /> : <IoSettingsOutline size={20} />}
                  </div>
                </div>
              </MenuHandler>

              {userRole !== '1' && (
                <MenuList className={`z-50 ${showMenu ? 'visible' : 'hidden'}`}>
                  <MenuItem
                    className={`flex items-center gap-2 ${isCurrentRoute('/viewBusiness') || (businessData.length > 0 && userRole === '3' && isCurrentRoute('/viewbusinessusers')) ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                    onClick={() => {
                      if (userRole === '2') {
                        navigate("/viewBusiness");
                      } else if (businessData.length > 0 && userRole === '3') {
                        navigate("/viewbusinessusers");
                      } else {
                        navigate("/business");
                      }
                      handleMenuItemClick();
                    }}
                    style={{
                      backgroundColor: isCurrentRoute('/viewBusiness') || (businessData.length > 0 && userRole === '3' && isCurrentRoute('/viewbusinessusers')) ? '#E5E7EB' : 'transparent',
                      color: 'inherit'
                    }}
                  >
                    <MdOutlineBusinessCenter size={23} />
                    <Typography variant="small" className="font-normal">
                      Business
                    </Typography>
                  </MenuItem>

                  {userRole !== '2' && (
                    <>
                      {businessType === "1" && (
                        <>
                          <MenuItem
                            className={`flex items-center gap-2 ${isCurrentRoute('/products') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                            onClick={() => { navigate(`/products`); handleMenuItemClick(); }}
                            style={{
                              backgroundColor: isCurrentRoute('/products') ? '#E5E7EB' : 'transparent',
                              color: 'inherit'
                            }}
                          >
                            <FaProductHunt size={20} />
                            <Typography variant="small" className="font-normal">
                              Products
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            className={`flex items-center gap-2 ${isCurrentRoute('/itemsdashboard') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                            onClick={() => { navigate(`/itemsdashboard`); handleMenuItemClick(); }}
                            style={{
                              backgroundColor: isCurrentRoute('/itemsdashboard') ? '#E5E7EB' : 'transparent',
                              color: 'inherit'
                            }}
                          >
                            <FaMoneyBillAlt size={20} />
                            <Typography variant="small" className="font-normal">
                              Items
                            </Typography>
                          </MenuItem>
                        </>
                      )}

                      {businessType === "2" && (
                        <MenuItem
                          className={`flex items-center gap-2 ${isCurrentRoute('/vendors') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                          onClick={() => { navigate(`/vendors`); handleMenuItemClick(); }}
                          style={{
                            backgroundColor: isCurrentRoute('/vendors') ? '#E5E7EB' : 'transparent',
                            color: 'inherit'
                          }}
                        >
                          <ReduceCapacityOutlinedIcon size={20} />
                          <Typography variant="small" className="font-normal">
                            Vendors
                          </Typography>
                        </MenuItem>
                      )}

                      <MenuItem
                        className={`flex items-center gap-2 ${isCurrentRoute('/customers') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                        onClick={() => { navigate(`/customers`); handleMenuItemClick(); }}
                        style={{
                          backgroundColor: isCurrentRoute('/customers') ? '#E5E7EB' : 'transparent',
                          color: 'inherit'
                        }}
                      >
                        <HiUserGroup size={20} />
                        <Typography variant="small" className="font-normal">
                          Customers
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              )}
            </Menu>

          
          
            {/* <div
                  onMouseEnter={handleWindowsMouseEnter}
                  onMouseLeave={handleWindowsMouseLeave}
                  style={{
                    backgroundColor: isWindowsHovered ? "#e0f7fa" : "transparent",
                    color: isWindowsHovered ? "#00acc1" : "gray",
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                  
                  }}
                >
                  {isWindowsHovered ? (
                    <AiFillWindows  size={20} />
                  ) : (
                    <AiOutlineWindows size={20} />
                  )}
            </div> */}



      
          <FaUserSecret
            className="text-gray-400 hover:text-orange-600  hover:bg-orange-200 rounded-full  p-2"
            size={37}
          />


       {user_role !== "1" && (   
          <div
            onMouseEnter={handleBellMouseEnter}
            onMouseLeave={handleBellMouseLeave}
            style={{
              backgroundColor: isBellHovered ? "#f8d1e8" : "transparent",
              color: isBellHovered ? "#d61f69" : "gray",
              display: "inline-block",
              padding: "10px",
              borderRadius: "50%",
              transition: "all 0.3s ease",
            }}
          >
            {isBellHovered ? (
              <BsFillBellFill size={20} />
            ) : (
              <BsBell size={20} />
            )}
          </div> )}


          <Menu>
            <MenuHandler>
              <div className="cursor-pointer">
                <BiSolidUser
                  className="text-green-500  hover:bg-green-100 rounded-full  p-2"
                  size={39}
                />
              </div>
            </MenuHandler>
            <MenuList className="z-50">
              <MenuItem className="flex items-center gap-2">
                <div class=" py-1 text-sm text-gray-900 dark:text-white">
                  <div>{contact_name}</div>
                  <div class="font-medium truncate">{contact_email}</div>
                </div>
              </MenuItem>

              <hr className="my-2 border-blue-gray-50" />

              
            
                  <MenuItem className="flex items-center gap-2 "
                  onClick={handleSignOut}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                      />
                    </svg>
                    <Typography variant="small" className="font-normal">
                      Sign Out
                    </Typography>
                  </MenuItem>
            

            </MenuList>
          </Menu>
        </div>
      </header>
        
        {/* {userRole !== "1" && (
            <marquee
                    behavior="scroll"
                    direction="left"
                    scrollamount="5"
                    onMouseOver={(e) => e.target.stop()}
                    onMouseOut={(e) => e.target.start()}
                    style={{ color: 'red', whiteSpace: 'nowrap' }}
                >
            {scrollingMessage}
          </marquee> )} */}

      {/* <header className="bg-white h-14 mt-1 flex items-center justify-between px-4 sticky top-0 z-50">
          <marquee
              behavior="scroll"
              direction="left"
              scrollamount="5"
              onMouseOver={(e) => e.target.stop()}
              onMouseOut={(e) => e.target.start()}
              style={{ color: 'red', whiteSpace: 'nowrap' }}
          >
            {scrollingMessage}
          </marquee>
    </header> */}
    </>
  );
}

export default Header;