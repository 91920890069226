import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import ReactDOM from 'react-dom';

export const showToastForAddInvoice = (message, type, businessName) => {
    const toastColorClass = type === "success" ? "bg-green-50 text-green-400" : "bg-green-50 text-green-400";
    const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#aedfb4]" size={24} /> : <FaTimesCircle className="mr-2 text-green-500" size={24} />;
  
    const iconContainer = document.createElement('div');
    ReactDOM.render(iconComponent, iconContainer);
  
    const toastContainer = document.createElement('div');
    toastContainer.className = `toast-container flex justify-between items-center ${toastColorClass}`;
  
    toastContainer.innerHTML = `
      <div className="rounded-lg" style="white-space: nowrap; width: 600px;"> 
        ${message}
      </div>
      <div className="rounded-lg">${iconContainer.innerHTML}</div>
    `;
    toastContainer.style.position = 'fixed';
    toastContainer.style.top = '358px'; // Fixed vertical position
    toastContainer.style.left = '15%';
    toastContainer.style.transform = 'translateX(-100%)'; // Start off-screen to the left
    toastContainer.style.opacity = '0'; // Start fully transparent
    toastContainer.style.zIndex = '9999';
    toastContainer.style.padding = '10px';
    toastContainer.style.borderLeft = type === "success" ? "5px solid #2BDE3F" : "5px solid green";
    toastContainer.style.background = "##f3faf7"
    toastContainer.style.minHeight = "10px";
    toastContainer.style.display = "flex";
    toastContainer.style.justifyContent = "space-between"; 
    toastContainer.style.width = "82%"; // Adjust width as needed
    toastContainer.style.transition = 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out'; // Transition for smooth slide-in/out
    toastContainer.style.margin = '10px 0';
    document.body.appendChild(toastContainer);
  
    // Trigger the slide-in animation
    requestAnimationFrame(() => {
      toastContainer.style.transform = 'translateX(0)';
      toastContainer.style.opacity = '1';
    });
  
    const initialTop = parseFloat(getComputedStyle(toastContainer).top);
  
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const distance = initialTop - scrollTop;
      toastContainer.style.top = `${distance}px`; // Adjust position based on scroll
    };
  
    window.addEventListener('scroll', handleScroll);
  
    setTimeout(() => {
      // Trigger the slide-out animation
      toastContainer.style.transform = 'translateX(-100%)';
      toastContainer.style.opacity = '0';
  
      setTimeout(() => {
        document.body.removeChild(toastContainer);
        window.removeEventListener('scroll', handleScroll);
      }, 500); // Match the duration of the slide-out transition
    }, 5000); // Duration to show the toast before hiding
  };

  export const showToastForUpdateInvoice = (message, type, businessName) => {
    const toastColorClass = type === "success" ? "bg-green-50 text-blue-500" : "bg-red-50 text-red-500";
    const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#2b88de]" size={24} /> : <FaTimesCircle className="mr-2 text-red-500" size={24} />;
  
    const iconContainer = document.createElement('div');
    ReactDOM.render(iconComponent, iconContainer);
  
    const toastContainer = document.createElement('div');
    toastContainer.className = `toast-container flex justify-between items-center ${toastColorClass}`;
  
    toastContainer.innerHTML = `
      <div className="rounded-lg" style="white-space: nowrap; width: 600px; "> 
        ${message}
      </div>
      <div className="rounded-lg">${iconContainer.innerHTML}</div>
    `;
    toastContainer.style.position = 'fixed';
    toastContainer.style.top = '358px'; // Fixed vertical position
    toastContainer.style.left = '15%';
    toastContainer.style.transform = 'translateX(-100%)'; // Start off-screen to the left
    toastContainer.style.opacity = '0'; // Start fully transparent
    toastContainer.style.zIndex = '9999';
    toastContainer.style.padding = '10px';
    toastContainer.style.borderLeft = type === "success" ? "5px solid #2b88de" : "5px solid red";
    toastContainer.style.background = type === "success" ? "#f3faf7" : "#fdf2f2";
    toastContainer.style.minHeight = "10px";
    toastContainer.style.display = "flex";
    toastContainer.style.justifyContent = "space-between";
    toastContainer.style.width = "82%"; // Adjust width as needed
    toastContainer.style.transition = 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out'; // Transition for smooth slide-in/out
    toastContainer.style.margin = '10px 0'; // Add top and bottom margin
  
    document.body.appendChild(toastContainer);
  
    // Trigger the slide-in animation
    requestAnimationFrame(() => {
      toastContainer.style.transform = 'translateX(0)';
      toastContainer.style.opacity = '1';
    });
  
    const initialTop = parseFloat(getComputedStyle(toastContainer).top);
  
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const distance = initialTop - scrollTop;
      toastContainer.style.top = `${distance}px`; // Adjust position based on scroll
    };
  
    window.addEventListener('scroll', handleScroll);
  
    setTimeout(() => {
      // Trigger the slide-out animation
      toastContainer.style.transform = 'translateX(-100%)';
      toastContainer.style.opacity = '0';
  
      setTimeout(() => {
        document.body.removeChild(toastContainer);
        window.removeEventListener('scroll', handleScroll);
      }, 500); // Match the duration of the slide-out transition
    }, 5000); // Duration to show the toast before hiding
  };
  

  export const showToastForDeleteInvoice = (message, type, businessName) => {
    const toastColorClass = type === "success" ? "bg-green-50 text-red-500" : "bg-red-50 text-red-500";
    const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#de402b]" size={24} /> : <FaTimesCircle className="mr-2 text-red-500" size={24} />;
  
    const iconContainer = document.createElement('div');
    ReactDOM.render(iconComponent, iconContainer);
  
    const toastContainer = document.createElement('div');
    toastContainer.className = `toast-container flex justify-between items-center ${toastColorClass}`;
  
    toastContainer.innerHTML = `
      <div className="rounded-lg" style="white-space: nowrap; width: 600px;"> 
        ${message}
      </div>
      <div className="rounded-lg">${iconContainer.innerHTML}</div>
    `;
    toastContainer.style.position = 'fixed';
    toastContainer.style.top = '358px'; 
    toastContainer.style.left = '15%';
    toastContainer.style.transform = 'translateX(-100%)'; 
    toastContainer.style.opacity = '0';
    toastContainer.style.zIndex = '9999';
    toastContainer.style.padding = '10px';
    toastContainer.style.borderLeft = type === "success" ? "5px solid #de402b" : "5px solid red";
    toastContainer.style.background = type === "success" ? "#f3faf7" : "#fdf2f2";
    toastContainer.style.minHeight = "10px";
    toastContainer.style.display = "flex";
    toastContainer.style.justifyContent = "space-between";
    toastContainer.style.width = "82%"; 
    toastContainer.style.transition = 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out'; 
    toastContainer.style.margin = '10px 0';
    document.body.appendChild(toastContainer);
  
    // Trigger the slide-in animation
    requestAnimationFrame(() => {
      toastContainer.style.transform = 'translateX(0)';
      toastContainer.style.opacity = '1';
    });
  
    const initialTop = parseFloat(getComputedStyle(toastContainer).top);
  
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const distance = initialTop - scrollTop;
      toastContainer.style.top = `${distance}px`; // Adjust position based on scroll
    };
  
    window.addEventListener('scroll', handleScroll);
  
    setTimeout(() => {
      // Trigger the slide-out animation
      toastContainer.style.transform = 'translateX(-100%)';
      toastContainer.style.opacity = '0';
  
      setTimeout(() => {
        document.body.removeChild(toastContainer);
        window.removeEventListener('scroll', handleScroll);
      }, 500); // Match the duration of the slide-out transition
    }, 5000); // Duration to show the toast before hiding
  };