import React from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./components/constants/routes.constants";
import Home from "./components/pages/Home";
import InvoiceForms from "./components/forms/InvoiceForms";
import CustomerForm from "./components/forms/CustomerForm";
import Invoice from "./components/pages/Invoice";
import Customer from "./components/pages/Customer";
import Product from "./components/pages/Product";
import Inventory from "./components/pages/Inventory";
import Billing from "./components/pages/Billing";
import Payments from "./components/pages/Payments";
import Setup from "./components/pages/Setup";
import ViewCustomer from "./components/forms/ViewCustomer";
import ProductForm from "./components/forms/ProductForm";
import ItemForm from "./components/forms/ItemForm";
import ViewProduct from "./components/forms/ViewProduct";
import ViewItem from "./components/forms/ViewItem";
import { TabsWithComponent } from "./components/pages/Tabs";
import Items from "./components/pages/Items";
import Business from "./components/pages/Business";
import BusinessDashboard from "./components/pages/BusinessDashboard";
import ViewBusiness from "./components/forms/ViewBusiness";
import LoginPage from "./components/pages/LoginPage";
import ForgotPassword from "./components/pages/ForgotPassword";
import SignUpPage from "./components/pages/SignUpPage";
import OtpPage from "./components/pages/OtpPage";
import ViewBusinessUsers from "./components/forms/ViewBusinessUsers";
import CreateOwner from "./components/Admin/CreateOwner";
import CreateBusiness from "./components/Admin/CreateBusiness";
import UsersDashBoard from "./components/Admin/UsersDashBoard";
import AdminBusinessDashboard from "./components/Admin/AdminBusinessDashboard";
import UserLoginPage from "./components/pages/UserLoginPage";
import ItemPrices from "./components/pages/ItemPrices";
import InvoiceFormTwo from "./components/forms/InvoiceFormTwo";
import ViewInvoiceForm from "./components/forms/ViewInvoiceForm";
import ViewInvoiceFormTwo from "./components/forms/ViewInvoiceFormTwo";
import Purchases from "./components/pages/Purchases";
import Expenses from "./components/pages/Expenses";
import Deposits from "./components/pages/Deposits";
import Documents from "./components/pages/Documents";
import Vendors from "./components/pages/Vendors";
import CreateVendor from "./components/gasForms/CreateVendor";
import ViewVendor from "./components/gasForms/ViewVendor";
import ItemDashboard from "./components/pages/ItemDashboard";
import Notifications from "./components/Admin/Notifications";
import EditNotification from "./components/Admin/EditNotification";
import EditBusiness from "./components/Admin/EditBusiness";
import EditOwner from "./components/Admin/EditOwner"
import CreateOwnerMain from "./components/Admin/CreateOwnerMain";

const AppRoutes = () => {

 
 

  return (
    <Routes>
       <Route path={routes.SIGNUP_PAGE} element={<SignUpPage />} />
       <Route path={routes.OTP_PAGE} element={<OtpPage />} />
       {/* <Route path={routes.LOGIN_PAGE} element={<LoginPage />} /> */}
       <Route path={routes.ADMIN_LOGIN} element={<LoginPage />} />
       <Route path={routes.USER_LOGIN_PAGE} element={<UserLoginPage />} />
       {/* <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} /> */}
      <Route path={routes.HOME} element={<Home />} />
      <Route path={routes.INVOICE} element={<Invoice />} />
      <Route path={routes.CUSTOMERS} element={<Customer />} />
      <Route path={routes.PURCHASES} element={<Purchases />} />
      <Route path={routes.EXPENSES} element={<Expenses />} />
      <Route path={routes.PAYMENTS} element={<Payments />} />
      <Route path={routes.DEPOSITS} element={<Deposits />} />
      <Route path={routes.DOCUMENTS} element={<Documents />} />

      <Route path={routes.BUSINESS} element={<Business />} />
      <Route path={routes.PRODUCTS} element={<Product />} />
      <Route path={routes.ITEMS} element={<Items />} />
      <Route path={routes.VENDORS} element={<Vendors />} />

      <Route path={routes.INVENTORY} element={<Inventory />} />
      <Route path={routes.BILLING} element={<Billing />} />
      {/* <Route path={routes.PAYMENTS} element={<Payments />} /> */}
      <Route path={routes.SETUP} element={<TabsWithComponent />} />
      <Route path={routes.BUSINESS_DASHBOARD} element={<BusinessDashboard />} />

      <Route path={routes.INVOICE_FORM} element={<InvoiceForms />} />
      <Route path={routes.CUSTOMER_FORM} element={<CustomerForm />} />
      <Route path={routes.VIEW_CUSTOMER} element={<ViewCustomer />} />
      <Route path={routes.VIEW_BUSINESS} element={<ViewBusiness />} />
      <Route path={routes.VIEW_BUSINESS_USERS} element={<ViewBusinessUsers />} />
      <Route path={routes.CREATE_OWNER} element={<CreateOwner />} />
      <Route path={routes.CREATE_OWNER_MAIN} element={<CreateOwnerMain />} />

      <Route path={routes.CREATE_BUSINESS} element={<CreateBusiness />} />
      <Route path={routes.USERS_DASHBOARD} element={<UsersDashBoard />} />
      <Route path={routes.ADMIN_BUSINESS_DASHBOARD} element={<AdminBusinessDashboard />} />
      <Route path={routes.ITEM_PRICE} element={<ItemPrices />} />
      <Route path={routes.INVOICE_FORM_TWO} element={<InvoiceFormTwo />} />
      <Route path={routes.VIEW_INVOICE_FORM} element={<ViewInvoiceForm />} />
       <Route path={routes.VIEW_INVOICE_FORM_TWO} element={<ViewInvoiceFormTwo />} />
       <Route path={routes.CREATE_VENDOR} element={<CreateVendor />} />
       <Route path={routes.VIEW_VENDOR} element={<ViewVendor />} />
       <Route path={routes.ITEMS_DASHBOARD} element={<ItemDashboard />} />
       <Route path={routes.NOTIFICATIONS} element={<Notifications />} />
       <Route path={routes.EDIT_NOTIFICATIONS} element={<EditNotification />} />
       <Route path={routes.EDIT_ADMIN_BUSINESS} element={<EditBusiness />} />
       <Route path={routes.EDIT_ADMIN_OWNER} element={<EditOwner />} />

     

      {/* Dynamic routes for CustomerForm and ViewCustomer */}
      <Route path={`${routes.SETUP}/signup`} element={<SignUpPage />} />
      {/* <Route path={`${routes.SETUP}/loginpage`} element={<LoginPage />} /> */}
      <Route path={`${routes.SETUP}/adminlogin`} element={<LoginPage />} />
      <Route path={`${routes.SETUP}/userloginpage`} element={<UserLoginPage />} />
      <Route path={`${routes.SETUP}/otp`} element={<OtpPage />} />
      {/* <Route path={`${routes.SETUP}/forgotpassword`} element={<ForgotPassword />} /> */}
      <Route path={`${routes.SETUP}/customerform`} element={<CustomerForm />} />
      <Route path={`${routes.SETUP}/:products`} element={<TabsWithComponent />} />
      <Route path={`${routes.SETUP}/productform`} element={<ProductForm />} />
      <Route path={`${routes.SETUP}/business`} element={<Business />} />
      <Route path={`${routes.SETUP}/businessdashboard`} element={<BusinessDashboard />} />
      <Route path={`${routes.SETUP}/viewbusinessusers`} element={<ViewBusinessUsers />} />
      <Route path={`${routes.SETUP}/itemform`} element={<ItemForm />} />
      <Route path={`${routes.CUSTOMERS}/viewcustomer/:customerId`} element={<ViewCustomer />} />
      <Route path={`${routes.SETUP}/viewproduct/:productId`} element={<ViewProduct />} />
      <Route path={`${routes.SETUP}/viewitem/:itemId`} element={<ViewItem />} />
      <Route path={`${routes.SETUP}/viewbusiness/:businessId`} element={<ViewBusiness />} />

      <Route path={`${routes.SETUP}/createowner`} element={<CreateOwner />} />
      <Route path={`${routes.SETUP}/createownermain`} element={<CreateOwnerMain />} />
      <Route path={`${routes.SETUP}/editadminowner/:id`} element={<EditOwner />} />

      <Route path={`${routes.SETUP}/createbusiness`} element={<CreateBusiness />} />
      <Route path={`${routes.EDIT_ADMIN_BUSINESS}/editadminbusiness/:id`} element={<EditBusiness />} />
      
      <Route path={`${routes.SETUP}/usersdashboard`} element={<UsersDashBoard />} />
      <Route path={`${routes.SETUP}/adminbusinessdashboard`} element={<AdminBusinessDashboard />} />
      <Route path={`${routes.ITEMS_DASHBOARD}/itemsdashboard`} element={<ItemDashboard />} />
      <Route path={`${routes.NOTIFICATIONS}/notifications`} element={<Notifications />} />
      <Route path={`${routes.EDIT_NOTIFICATIONS}/editnotifications/:id`} element={<EditNotification />} />
      
      {/* Dynamic routes for InvoiceForms */}
      <Route path={`${routes.INVOICE}/invoiceforms`} element={<InvoiceForms />} />
      <Route path={`${routes.CUSTOMERS}/customerforms`} element={<CustomerForm />} />
      <Route path={`${routes.PRODUCTS}/productform`} element={<ProductForm />} />
      
      <Route path={`${routes.ITEMS}/itemform`} element={<ItemForm />} />
      <Route path={`${routes.ITEM_PRICE}/itemprice`} element={<ItemPrices />} />
      <Route path={`${routes.INVOICE_FORM_TWO}/invoiceformtwo`} element={<InvoiceFormTwo />} />
      <Route path={`${routes.VIEW_INVOICE_FORM}/:invoicenumber`} element={<ViewInvoiceForm />} />
      <Route path={`${routes.VIEW_INVOICE_FORM_TWO}/:invoicenumber`} element={<ViewInvoiceFormTwo />} />
      <Route path={`${routes.CREATE_VENDOR}/createvendor`} element={<CreateVendor />} />
      <Route path={`${routes.VIEW_VENDOR}/:vendorid`} element={<ViewVendor />} />

     

    </Routes>
  );
};

export default AppRoutes;
